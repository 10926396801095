window.HORUS = window.HORUS || {};

var cookieApi = Cookies.noConflict();

HORUS.CONFIG = (function() {
    var private = {
        // place all constants here, access via HORUS.CONFIG.get('MY_CONST');
        'DATE_PICKER_FORMAT': 'dd-M-yy'
    };

    return {
        get: function(name) { return private[name]; }
    };
})();

Mustache.tags = ['<%', '%>'];

HORUS.forms = HORUS.forms || {};

HORUS.forms.extractIndexFromCollectionId = function (collectionId) {
    // e.g. list_of_favourite_cheese_99_cheeseName
    var tmp = collectionId.split('_');
    return tmp[tmp.length - 2]; // index will be second from end
};

HORUS.forms.getHtmlStringForCheckboxInCollection = function (formName, fieldName, itemId) {
    var template = '<input type="checkbox" name="<% formName %>[<% fieldName %>][]" value="<% itemId %>">';

    var templateData = {
        formName: formName,
        fieldName: fieldName,
        itemId: itemId
    };

    return Mustache.render(template, templateData);
};

HORUS.ajax = HORUS.ajax || {};

HORUS.ajax.traineeRotations = function (traineeId, callback) {
    if (typeof traineeId === 'undefined' || !traineeId
        || (typeof traineeId !== 'number' && typeof traineeId !== 'string')) {
        throw 'HORUS.ajax.traineeRotations: traineeId must be a valid number or string';
    }

    return jQuery.post('/ajax-rotations/trainee/' + traineeId, [], function (responseData) {
        if (typeof callback === 'function') {
            callback(responseData);
        }
    }, 'json');
};

HORUS.assetPathAbsolute = $('#asset-path-absolute').prop('content');

HORUS.cookieManager = (function () {
    var isHttps = location.protocol === 'https:';

    var defaultSettings = {
        expires: 365,
        secure: isHttps,
        sameSite: 'strict'
    };

    var cookiePreferences = (function () {
        var cookiePreferencesVersion = undefined;
        var cookiePreferencesCookieName = undefined;

        var buildCookiePreferenceCookieValue = function (localStorageIsGranted) {
            var cookiePreferencesObject = {
                version: cookiePreferencesVersion,
                updatedOn: (new Date()).toUTCString(),
                preferences: {
                    localStorage: localStorageIsGranted ? 'granted' : 'denied'
                }
            };
            return JSON.stringify(cookiePreferencesObject);
        };

        var getCookiePreferenceObject = function () {
            var cookiePreferences = cookieApi.get(cookiePreferencesCookieName);
            if (cookiePreferences === undefined) {
                return undefined;
            }
            return JSON.parse(cookiePreferences);
        };

        return {
            initialise: function (cookieName, cookieVersion) {
                cookiePreferencesCookieName = cookieName;
                cookiePreferencesVersion = cookieVersion;
            },
            isCompleted: function () {
                var cookiePreferences = getCookiePreferenceObject();
                if (cookiePreferences === undefined) {
                    return false;
                }
                return cookiePreferences.version === cookiePreferencesVersion;
            },
            setLocalStorageGranted: function () {
                cookieApi.set(cookiePreferencesCookieName, buildCookiePreferenceCookieValue(true), defaultSettings);
            },
            setLocalStorageDenied: function () {
                cookieApi.set(cookiePreferencesCookieName, buildCookiePreferenceCookieValue(false), defaultSettings);
            },
            isLocalStorageGranted: function () {
                var cookiePreferences = getCookiePreferenceObject();
                if (cookiePreferences === undefined) {
                    return false;
                }
                if (! cookiePreferences.version === cookiePreferencesVersion) {
                    return false;
                }
                return cookiePreferences.preferences.localStorage === 'granted';
            },

        };
    })();

    return {
        cookiePreferences: cookiePreferences,
        getLocalStorageDisabledMessageElement: function (pageSavesOrderingPreferences) {
            pageSavesOrderingPreferences = pageSavesOrderingPreferences || false;

            var message = 'This page has reduced functionality due to Local Storage being disabled. Your filtering' +
                (pageSavesOrderingPreferences ? ' and ordering' : '') + ' preferences will not be saved after leaving' +
                ' the page. To enable these features please edit your Cookie Preferences via the footer link.';

            return $('<div />')
                .addClass('info-message small')
                .text(message);
        },
        utils: {
            clearOldCookies: function () {
                var oldCookies = ['ai_user', 'ai_session'];
                oldCookies.forEach(function (oldCookie) {
                    cookieApi.set(oldCookie, 'overwrite to remove expired');
                    cookieApi.remove(oldCookie);
                });
            },
            clearLocalAndSessionStorage: function () {
                sessionStorage.clear();
                localStorage.clear();
            }
        }
    };
})();

HORUS.cookieManager.utils.clearOldCookies();

HORUS.localStorageSetItemIfUserGranted = function (name, value) {
    if (HORUS.cookieManager.cookiePreferences.isLocalStorageGranted()) {
        localStorage.setItem(name, value);
    }
};

// Add bind function for wkhtmltopdf
Function.prototype.bind = Function.prototype.bind || function (thisp) {
    var fn = this;
    return function () {
        return fn.apply(thisp, arguments);
    };
}

HORUS.sessionTimeoutWarning = function() {
    if (typeof Date !== 'function') {
        return;
    }

    var oneSecond = 1000;
    var oneMinute = oneSecond * 60;
    var oneHour = oneMinute * 60;

    // Update this if session.gc_maxlifetime is changed
    var sessionLifetime = oneHour * 3;
    var warningPeriod = oneMinute * 15;

    var sessionEndDate = new Date((new Date()).getTime() + sessionLifetime);
    var warningDate = new Date((new Date()).getTime() + (sessionLifetime - warningPeriod));

    var warningActive = false;
    var warningDismissed = false;

    var warningDialog = null;
    var errorDialog = null;
    var sessionEndDialog = null;

    var warningInterval = setInterval(function () {
        var dateNow = new Date();

        if (
            dateNow >= warningDate
            && ! warningActive
            && ! warningDismissed
            && ! (sessionEndDialog instanceof HORUS.dialog)
        ) {
            warningActive = true;

            var buttons = [{
                label: 'Close',
                isDefault: true,
                callback: function () {
                    warningActive = false;
                    warningDismissed = true;
                },
            }, {
                label: 'Stay Logged In',
                callback: function () {
                    warningActive = false;

                    $.post('/session-api/extend-session', function (responseData) {
                        if (responseData.data.items[0].status === '0') {
                            clearInterval(warningInterval);

                            errorDialog = new HORUS.dialog('');
                            errorDialog.show();

                            var errorHtmlMessage = '<p class="margin-top-none">Extending your session has ' +
                                'failed, and you are now logged out.</p><p>If you have work you have not saved, ' +
                                'you should ensure to copy and paste your work to a word processing file or ' +
                                'print this page as a PDF by clicking your browsers menu, clicking ' +
                                '<em>Print...</em> and selecting the option to save to PDF.</p>' +
                                '<p class="margin-bottom-none">If you click any links, close or refesh this ' +
                                'page before you copy your work, it will be lost.</p>';

                            $('.dialog__message').html(errorHtmlMessage);
                        } else {
                            warningDate = new Date((new Date()).getTime() + (sessionLifetime - warningPeriod));
                            sessionEndDate = new Date((new Date()).getTime() + sessionLifetime);
                        }
                    });
                },
            }];

            warningDialog = new HORUS.dialog('', buttons);
            warningDialog.show();

            var warningHtmlMessage = '<p class="margin-top-none">Your session will end soon, and you will be logged ' +
                'out.</p><p class="margin-bottom-none">If you wish to remain logged in please click Stay Logged In. ' +
                'Alternatively, you can click Close and save your work before your session ends.</p>';

            $('.dialog__message').html(warningHtmlMessage);
        }

        if (dateNow >= sessionEndDate) {
            clearInterval(warningInterval);

            if (warningDialog instanceof HORUS.dialog) {
                warningDialog.hide();

                warningActive = false;
            }

            if (errorDialog instanceof HORUS.dialog) {
                errorDialog.hide();
            }

            sessionEndDialog = new HORUS.dialog('');
            sessionEndDialog.show();

            var sessionEndMessage = '<p class="margin-top-none">Your session has ended, and you have been logged ' +
                'out.</p><p>If you have work you have not saved, you should ensure to copy and paste your work ' +
                'to a word processing file or print this page as a PDF by clicking your browsers menu, clicking ' +
                '<em>Print...</em> and selecting the option to save to PDF.</p><p class="margin-bottom-none">If ' +
                'you click any links, close or refesh this page before you copy your work, it will be lost.</p>';

            $('.dialog__message').html(sessionEndMessage);
        }
    }, oneSecond * 5);
};
