(function ($) {
    $.fn.sendForApprovalPortfolioItem = function () {
        return this.each(function () {
            var $this = $(this);

            var dialogWording = 'Are you sure you wish to send this form for approval? ' +
                'This can\'t be undone and no further changes will be possible.';

            $this.confirmClick({
                cancelCallback: function () {
                    $(this).protectClickTwiceReset();
                },
                confirmMessage: dialogWording,
                okLabel: 'Send for approval'
            });
        });
    };
}(jQuery));