/**
 * infoIconPopup plugin
 *
 * Takes the id from the data-target-id attribute from the target elements and looks
 * for an element where the id ends with the id string and creates an info icon with
 * the content. If the element is a form element (or UL due to the way Symfony creates HTML for
 * radio and checkbox fields), the plugin will look for a Symfony generated label,
 * a label with the relevant "for" attribute or a label which is a descendant of the
 * element's parent.
 *
 * Usage for info div:
 *
 *      <div data-target-id="field" class="js-info-icon-popup">
 *          <p>This div will appear when the info icon is clicked.</p>
 *      <div>
 *
 *      <script type="text/javascript">
 *          $(function() {
 *              $('.js-info-icon-popup').infoIconPopup();
 *          });
 *      </script>
 *
 * Examples of elements where info icon is applied directly to field:
 *
 * <h1 id="field">This header has an info icon</h1>
 *
 * <span id="field">This span has an info icon</span>
 *
 * <div id="field">This div has an info icon</div>
 *
 * Examples of elements where info icon is applied to label:
 *
 * <div class="form-row">
 *     <span class="form-row__label">
 *         <label for="form_name_field" class="required">This label</label>
 *     </span>
 *     <input type="text" id="form_name_field" name="form_name[field]">
 * </div>
 *
 * <div>
 *     <label for="form_name_field">This label</label>
 *     <input type="text" id="form_name_field" name="form_name[field]">
 * </div>
 *
 * <div>
 *     <label>This label</label>
 *     <input type="text" id="form_name_field" name="form_name[field]">
 * </div>
 */
(function ($) {

    // used by multiple plugins, so kept in higher function namespace
    var infoIconPopupClass = 'info-icon-popup';

    $.fn.infoIconPopup = function () {
        var options = {};
        var method = null;

        if (typeof arguments[0] !== 'undefined' && typeof arguments[1] === 'undefined') {
            if ($.type(arguments[0]) === 'string') {
                method = arguments[0];
            } else if ($.type(arguments[0]) === 'object') {
                options = $.extend(options, arguments[0]);
            }
        }

        if (typeof arguments[0] !== 'undefined' && typeof arguments[1] !== 'undefined') {
            method = arguments[0];
            options = $.extend(options, arguments[1]);
        }

        var $thisRoot = $(this);
        var formIconClass = 'js-form-icon';
        var infoIconClass = 'info-icon';
        var haltClass = 'infoIconPopup--halt';

        if (method === 'halt') {
            $thisRoot.addClass(haltClass);

            $thisRoot.find('.' + infoIconClass).hide();

            return this;
        }

        if (method === 'resume') {
            $thisRoot.removeClass(haltClass);

            $thisRoot.find('.' + infoIconClass).show();

            return this;
        }

        $thisRoot.addClass(infoIconPopupClass);

        $(document).click(function (e) {
            var $this = $(e.target);

            var isIcon = $this.hasClass(formIconClass);
            var isPopupOrChildOfPopup = $this.hasClass(infoIconPopupClass) || $this.closest('.' + infoIconPopupClass).length > 0;

            if (! isIcon && ! isPopupOrChildOfPopup) {
                $thisRoot.hide();
            }
        });

        return this.each(function () {
            var $this = $(this);
            var fieldId = $this.attr('data-target-id') ? $this.attr('data-target-id') : $this.attr('id').replace(/Info$/, '');
            var $formField = $('[id$="' + fieldId + '"]');
            var $iconTarget = $formField;

            if ($formField.length == 0) {
                return;
            }

            if ($.inArray($formField.prop('tagName'), ['INPUT', 'TEXTAREA', 'SELECT', 'METER', 'PROGRESS', 'UL']) > -1) {
                var $symfonyFormLabel = $formField.siblings('span.form-row__label').first();

                if ($symfonyFormLabel.length > 0) {
                    $iconTarget = $symfonyFormLabel;
                } else {
                    var $forLabel = $('label[for$="' + fieldId + '"]');

                    if ($forLabel.length > 0) {
                        $iconTarget = $forLabel;
                    } else {
                        var $closestLabel = $formField.parent().find('label').first();

                        if ($closestLabel.length > 0) {
                            $iconTarget = $closestLabel;
                        }
                    }
                }
            }

            if ($iconTarget.length == 0) {
                return;
            }

            $iconTarget.html($iconTarget.html() + ' ');

            var $infoIcon = $('<a/>', {
                'href': '#',
                'class': infoIconClass + ' ' + formIconClass
            }).click(function (e) {
                e.preventDefault();

                var infoIconPosition = $infoIcon.offset();
                var windowWidth = $(window).width();
                var infoIconPopupWidth = parseInt($this.css('max-width')) || 500;

                // Set default pop-up position to the right of the info icon
                var infoIconPopupLeft = infoIconPosition.left + 21;
                var infoIconPopupTop = infoIconPosition.top;

                // If the position of the right side of the pop-up (the left position plus the width) is more than the
                // window width, the pop-up will spill off the right side of the window, so we should position the
                // pop-up under the info icon and then determine its left value
                if (windowWidth < (infoIconPopupLeft + infoIconPopupWidth)) {
                    infoIconPopupTop = infoIconPosition.top + 21;

                    if ((infoIconPopupWidth + 20) > windowWidth) {
                        // If the width of the pop-up (plus the margin) is wider than the window, position at the
                        // left-most side of the window (the pop-up will scale to the width of the window)
                        infoIconPopupLeft = 20;
                    } else {
                        // Position the pop-up 20 pixels inside the right side of the screen
                        infoIconPopupLeft = windowWidth - infoIconPopupWidth - 20;
                    }
                }

                $this.css({
                    top: infoIconPopupTop,
                    left: infoIconPopupLeft
                });

                $thisRoot.not($this).hide();

                $this.toggle();
            }).appendTo($iconTarget);

            if ($infoIcon.closest('.' + haltClass).length) {
                $infoIcon.hide();
            }
        });
    };

    $.fn.addInfoIconPopupWithContent = function(content) {
        var elementId = $(this).attr('id');
        var infoTemplate = '<div id="<% elementId %>Info" class="<% infoIconPopupClass %>">' +
            '<p><% content %></p>' +
            '</div>';

        var infoHtml = Mustache.render(infoTemplate, {elementId: elementId, infoIconPopUpClass: infoIconPopupClass, content: content});

        var $info = $(infoHtml);

        $(this).after($info);

        $info.infoIconPopup();
    };
}(jQuery));