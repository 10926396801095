(function ($) {
    $.fn.configureRotationForm = function (options) {
        if (!options) {
            options = {};
        }

        var newRotation = false;
        var formHasErrors = false;
        if (options.hasOwnProperty('newRotation') && options.newRotation == true) {
            newRotation = true;
        }
        if (options.hasOwnProperty('formHasErrors') && options.formHasErrors == true) {
            formHasErrors = true;
        }

        var $placementCollectionHolder;
        var $newPlacementLinkLi;
        var $outOfProgrammePlacementCollectionHolder;
        var $$outOfProgrammePlacementLinkLi;
        var rotationFormPrefix = $('#configure_rotation').attr('name');

        var initForm = function () {
            initPlacementCollection();
            initOutOfProgrammePlacementCollection();
            initDatePickers();
            initAutocompleters();

            if (newRotation) {
                initRotationType();
                initAcademicYearChoice();
            } else {
                $('#step-2').show();
            }
        };

        var initPlacementCollection = function () {
            $placementCollectionHolder = $('ul.placements');
            var placementsAmount = $placementCollectionHolder.children('li').length;
            $placementCollectionHolder.data('index', placementsAmount);

            var $addPlacementLink = $('<a href="#" class="add-link">Add placement</a>');
            $newPlacementLinkLi = $('<li class="add-placement"></li>').append($addPlacementLink);
            $placementCollectionHolder.append($newPlacementLinkLi);
            $addPlacementLink.click(function(e) {
                e.preventDefault();
                addTrainingPlacementForm();
            });
            addCopyLinksToPlacements();
            addDeleteLinksToPlacements();
        };

        var initOutOfProgrammePlacementCollection = function () {
            $outOfProgrammePlacementCollectionHolder = $('ul.out-of-programme-placements');
            var outOfProgrammePlacementsAmount = $outOfProgrammePlacementCollectionHolder.children('li').length;
            $outOfProgrammePlacementCollectionHolder.data('index', outOfProgrammePlacementsAmount);

            var $addOutOfProgrammePlacementLink = $('<a href="#" class="add-link">Add out of programme placement</a>');
            $$outOfProgrammePlacementLinkLi = $('<li class="add-out-of-programme-placement"></li>').append($addOutOfProgrammePlacementLink);
            $outOfProgrammePlacementCollectionHolder.append($$outOfProgrammePlacementLinkLi);
            $addOutOfProgrammePlacementLink.click(function(e) {
                e.preventDefault();
                addOutOfProgrammePlacementForm();
            });

            addDeleteLinksToOutOfProgrammePlacements();
        };

        var initDatePickers = function () {
            $('.js-datepicker').datepicker({
                dateFormat: HORUS.CONFIG.get('DATE_PICKER_FORMAT')
            }).attr('autocomplete', 'off');
        };

        var initAutocompleters = function () {

            var getPlacementType = function (inputName) {
                var placementType = '';
                if (inputName.search("outOfProgrammePlacements") !== -1) {
                    placementType = 'outOfProgrammePlacements';
                } else {
                    placementType = 'placements'
                }
                return placementType;
            };

            $(".js-organisation-autocomplete").autocomplete({
                source: '/organisation/search-trust-autocomplete',
                minLength: 3,
                search: function (event, ui) {
                    var autoCompleteId = this.id;
                    var index = HORUS.forms.extractIndexFromCollectionId(autoCompleteId);
                    var placementType = getPlacementType($(this).attr('name'));
                    $("#" + rotationFormPrefix + "_" + placementType + "_" + index + "_organisationId").val('');
                },
                select: function (event, ui) {
                    var organisationId = ui.item.id;
                    if (organisationId) {
                        var autoCompleteId = this.id;
                        var index = HORUS.forms.extractIndexFromCollectionId(autoCompleteId);
                        var placementType = getPlacementType($(this).attr('name'));
                        $("#" + rotationFormPrefix + "_" + placementType + "_" + index + "_organisationId").val(organisationId);
                    }
                }
            });
        };

        var initRotationType = function () {
            $('[name="' + rotationFormPrefix + '[customRotation]"]').change(function () {
                switchRotationType(this.value);
            });

            var hasPlacements = $('#placements .form-row').length;
            var hasOutOfProgrammePlacements = $('#out-of-programme-placements .form-row').length;
            var rotationPartiallyConfigured = hasPlacements || hasOutOfProgrammePlacements;
            if (rotationPartiallyConfigured || formHasErrors) {
                // its a post back with data already
                $('#step-2').removeClass('hidden');
                $('#step-1').addClass('hidden');
            }
        };

        var switchRotationType = function (customRotation) {
            if (customRotation == 0) {
                $('#step-1-choose-academic-year').removeClass('hidden');
                $('#step-2').addClass('hidden');
            } else if (customRotation > 0) {
                $('#step-2').removeClass('hidden');
                $('#step-1').addClass('hidden');
                $('#step-1-choose-academic-year').addClass('hidden');
            }
        };

        var initAcademicYearChoice = function () {
            $('#' + rotationFormPrefix + '_academicYearId').change(function () {
                var academicYearId = this.value;
                if (academicYearId) {
                    loadPlacementTimePeriodsForAcademicYear(academicYearId);
                } else {
                    $('#step-2').addClass('hidden');
                    $('#step-1').addClass('hidden');
                }
            });
        };

        var loadPlacementTimePeriodsForAcademicYear = function (academicYearId) {
            var url = options.loadPlacementTimePeriodsUrl.replace("academicYearId", academicYearId);
            $('#loading').removeClass('hidden');
            $.ajax({
                type: "GET",
                url: url,
                dataType: 'json',
                success: function (response) {
                    if (response.hasOwnProperty('academicYear')) {
                        $('#' + rotationFormPrefix + '_beginDate').val(response.academicYear.beginDate.display);
                        $('#' + rotationFormPrefix + '_endDate').val(response.academicYear.endDate.display);
                        for (var i = 0; i < response.placementTimePeriod.length; i++) {
                            addTrainingPlacementForm();
                            var beginDateInputId = rotationFormPrefix + '_placements_' + i + '_beginDate';
                            var endDateInputId = rotationFormPrefix + '_placements_' + i + '_endDate';
                            $('#' + beginDateInputId).val(response.placementTimePeriod[i].beginDate.display);
                            $('#' + endDateInputId).val(response.placementTimePeriod[i].endDate.display);
                        }
                        $('#step-1').addClass('hidden');
                        $('#loading').addClass('hidden');
                        $('#step-2').removeClass('hidden');
                    }
                }
            });
        };

        var addTrainingPlacementForm = function ()
        {
            var prototype = $placementCollectionHolder.data('prototype');
            var placementAmountBeforeAdd = $placementCollectionHolder.data('index');
            var newForm = prototype.replace(/__name__/g, placementAmountBeforeAdd);
            $placementCollectionHolder.data('index', placementAmountBeforeAdd + 1);
            var $newFormLi = $('<li></li>').append(newForm);
            $newPlacementLinkLi.before($newFormLi);
            if($('.training-placement').length) {
                addCopyOrganisationLink($newFormLi);
            }
            addPlacementFormDeleteLink($newFormLi);
            addCopyLinksToPlacements();
            initDatePickers();
            initAutocompleters();
        };

        var addOutOfProgrammePlacementForm = function ()
        {
            var prototype = $outOfProgrammePlacementCollectionHolder.data('prototype');
            var placementAmountBeforeAdd = $outOfProgrammePlacementCollectionHolder.data('index');
            var newForm = prototype.replace(/__name__/g, placementAmountBeforeAdd);
            $outOfProgrammePlacementCollectionHolder.data('index', placementAmountBeforeAdd + 1);
            var $newFormLi = $('<li></li>').append(newForm);
            $$outOfProgrammePlacementLinkLi.before($newFormLi);
            addOutOfProgrammePlacementFormDeleteLink($newFormLi);
            initDatePickers();
            initAutocompleters();
        };

        var addDeleteLinksToPlacements = function () {
            $placementCollectionHolder.children('li').each(function () {
                if (!$(this).hasClass('add-placement')) {
                    var element = this;
                    if (canDeletePlacement(element)) {
                        addPlacementFormDeleteLink($(this));
                    }
                }
            });
        };

        var canDeletePlacement = function (element) {
            var canDeletePlacement = true;
            if (newRotation == false) {
                var elements = $(element).find('.placement-id');
                var placementId = elements[0].value;
                for (i = 0; i < options.nbOfPortfolioItemsGroupedByPlacement.length; i++) {
                    if (options.nbOfPortfolioItemsGroupedByPlacement[i].placementId == placementId) {
                        if (options.nbOfPortfolioItemsGroupedByPlacement[i].numberOfPortfolioItems) {
                            canDeletePlacement = false;
                        }
                    }
                }

                if (canDeletePlacement == true && options.readOnlyPlacements.length) {
                    if (options.readOnlyPlacements[placementId]) {
                        canDeletePlacement = false;
                    } else {
                        canDeletePlacement = true;
                    }
                }
            }
            return canDeletePlacement;
        };

        var addDeleteLinksToOutOfProgrammePlacements = function () {
            $outOfProgrammePlacementCollectionHolder.children('li').each(function () {
                if (!$(this).hasClass('add-out-of-programme-placement')) {
                    var element = this;
                    if (canDeleteOutOfProgrammePlacement(element)) {
                        addOutOfProgrammePlacementFormDeleteLink($(this));
                    }
                }
            });
        };

        var canDeleteOutOfProgrammePlacement = function (element) {
            var canDeleteOutOfProgrammePlacement = true;
            if (newRotation == false) {
                var elements = $(element).find('.out-of-programme-placement-id');
                var outOfProgrammePlacementId = elements[0].value;
                if (options.readOnlyOutOfProgrammePlacements.length) {
                    if (options.readOnlyOutOfProgrammePlacements[outOfProgrammePlacementId]) {
                        canDeleteOutOfProgrammePlacement = false;
                    } else {
                        canDeleteOutOfProgrammePlacement = true;
                    }
                }
            }
            return canDeleteOutOfProgrammePlacement;
        };

        var addCopyLinksToPlacements = function () {
            $placementCollectionHolder.find('.copy-organisation-link').each(function () {
                $(this).remove();
            });
            $placementCollectionHolder.children('li.training-placement').each(function (index) {
                if (index) {
                    addCopyOrganisationLink($(this));
                }
            });
        };

        var addCopyOrganisationLink = function ($placementFormLi) {
            var $prevPlacementFormLi = $placementFormLi.prev();
            var $copyOrganisationA = $('<a class="copy-organisation-link" href="#">Copy organisation from above</a>');
            $copyOrganisationA.click(function (e) {
                $placementFormLi.find('.organisation-name').val($prevPlacementFormLi.find('.organisation-name').val());
                $placementFormLi.find('.organisation-id').val($prevPlacementFormLi.find('.organisation-id').val());
                e.preventDefault();
            });
            var $customHtml = $('<div></div>').append($copyOrganisationA);
            $placementFormLi.append($customHtml);
        };

        var addPlacementFormDeleteLink = function ($placementFormLi) {
            var $removeFormA = $('<a href="#" class="button float-right">delete</a>');
            $placementFormLi.prepend($removeFormA);
            $removeFormA.click(function (e) {
                e.preventDefault();

                var removeDialogButtons = [{
                    label: 'OK',
                    callback: function () {
                        $placementFormLi.remove();
                        addCopyLinksToPlacements();
                    }
                }, {
                    label: 'Cancel'
                }];

                var removeDialog = new HORUS.dialog(
                    'Are you sure you want to delete this placement? The placement will disappear from your view of the ' +
                    'form, however the placement will not be removed from the system until you click the Save Changes ' +
                    'button at the end of this page.',
                    removeDialogButtons
                );

                removeDialog.show();
            });
        };

        var addOutOfProgrammePlacementFormDeleteLink = function ($outOfProgrammePlacementFormLi) {
            var $removeFormA = $('<a href="#" class="button float-right">delete</a>');
            $outOfProgrammePlacementFormLi.prepend($removeFormA);
            $removeFormA.click(function (e) {
                e.preventDefault();

                var removeDialogButtons = [{
                    label: 'OK',
                    callback: function () {
                        $outOfProgrammePlacementFormLi.remove();
                    }
                }, {
                    label: 'Cancel'
                }];

                var removeDialog = new HORUS.dialog(
                    'Are you sure you want to delete this placement? The placement will disappear from your view of the ' +
                    'form, however the placement will not be removed from the system until you click the Save Changes ' +
                    'button at the end of this page.',
                    removeDialogButtons
                );

                removeDialog.show();
            });
        };

        initForm();
    };
}(jQuery));