(function ($) {
    $.fn.organisationListModal = function () {
        return this.each(function () {
            var $this = $(this);

            var $modal = $('#' + $this.data('target-id'));

            var organisationCount = $modal.find('.employee-organisations ul.organisations-list li.organisation').length;

            if (organisationCount < 2) {
                $this.siblings('span').remove();

                var currentOrganisationName = $this.text();

                $this.replaceWith(function () {
                    return $('<span />').text(currentOrganisationName);
                });

                return;
            }

            $this.modal();

            $this.on('modal:show', function (e, $modal) {
                var $this = $(this);

                var currentOrganisationId = $this.data('organisation-id');

                var $currentOrganisationLink = $modal.find('.organisation__name[data-organisation-id="' + currentOrganisationId + '"]');

                var $currentOrganisationParents = $currentOrganisationLink.parents('.organisation__sub-organisations');

                $currentOrganisationParents.show();
            });
        });
    };
}(jQuery));