(function ($) {
    $.fn.trainerPicker = function(options) {
        if (!options) {
            options = {}
        }

        var that = this;
        var $trainerIdField = $('#' + $(this).attr('id') + 'Id');
        var $trainerAlternativeEmailField = $('#' + $(this).attr('id') + 'AlternativeEmail');
        var $trainerTypeField = $('#' + $(this).attr('id') + 'Type');
        var $jsRegisteredMedicalPractitionerInfo = $('.js-registered-medical-practitioner-info');

        var $trainerTextField = $('#' + $(this).attr('id'));
        if (options.hasOwnProperty('widePersonToApproveInputField') && options.widePersonToApproveInputField === true) {
            $trainerTextField.addClass('portfolio-item__text-input-wide-50');
        }

        var initForm = function() {
            var formIsReadOnly = ($(that).closest('form').attr('readonly') == 'readonly');
            if (formIsReadOnly == true) {
                if ($trainerIdField.val().length) {
                    $("#trainer-email").show();
                    $("#trainer-known-as").show();
                    if ($trainerAlternativeEmailField.val().length) {
                        $("#alternative-email-description").show();
                        $("#alternative-email").show();
                    }
                }
            } else {
                if ($trainerIdField.val().length) {
                    $("#trainer-email").show();
                    $("#trainer-known-as").show();
                    if ($trainerAlternativeEmailField.val().length) {
                        $("#use-default-email").show();
                        $("#alternative-email").show();
                    } else {
                        $("#provide-alternative-email").show();
                    }
                }
            }
        };

        var autocompleteUrl = '/autocomplete/trainers';
        if (options.hasOwnProperty('includeF2Trainees') && options.includeF2Trainees == true) {
            autocompleteUrl = '/autocomplete/trainers-including-f2-trainees';
        }

        var resetTrainerIdFieldAndAlternativeEmailUI = function () {
            $trainerIdField.val('');
            $trainerTypeField.val('');
            $("#trainer-email").hide();
            $("#trainer-known-as").html('');
            $("#provide-alternative-email").hide();
            $("#use-default-email").hide();
            $("#alternative-email").hide();
            $trainerAlternativeEmailField.val('');
            $("#primary-trainer-email").html('');
            $jsRegisteredMedicalPractitionerInfo.hide();
        };

        // force reset when the search field is completely blank
        $(this).focusout(function () {
            if ($(this).val() == '') {
                resetTrainerIdFieldAndAlternativeEmailUI();
            }
        });

        var updateTrainerIdFieldAndShowAlternativeEmailUI = function (trainerData) {
            var validEmployeeChosen = $.isNumeric(trainerData.id) && trainerData.type;

            if (! validEmployeeChosen) {
                $trainerIdField.val('');
                $trainerTypeField.val('');

                return;
            }

            $trainerIdField.val(trainerData.id);
            $trainerTypeField.val(trainerData.type);

            if (trainerData.knownAs) {
                $('#trainer-known-as').html('Known as: '+ trainerData.knownAs);
            }

            $('#trainer-email').show();
            $('#trainer-known-as').show();
            $('#provide-alternative-email').show();
            $('#use-default-email').hide();
            $('#alternative-email').hide();
            $('#primary-trainer-email').html(trainerData.email);

            if (trainerData.registeredMedicalPractitionerDataAvailable === true) {
                $jsRegisteredMedicalPractitionerInfo.show();

                var gmcNumber = trainerData.gmcNumber;
                var designatedBody = trainerData.designatedBody || 'unknown';
                var specialty = trainerData.specialty || 'unknown';
                var qualificationInfoParts = [];
                if (trainerData.yearOfQualification) {
                    qualificationInfoParts.push(trainerData.yearOfQualification);
                }
                if (trainerData.university) {
                    qualificationInfoParts.push(trainerData.university);
                }
                if (trainerData.qualificationCountry) {
                    qualificationInfoParts.push(trainerData.qualificationCountry);
                }

                $('.js-gmc-number').text(gmcNumber);
                $('.js-designated-body').text(designatedBody);
                $('.js-specialty').text(specialty);
                $('.js-qualification-info').text(qualificationInfoParts.join(', '));
            }
        };

        $(this).autocomplete({
            source: autocompleteUrl,
            minLength: 3,
            delay: 1000,
            search: function (event, ui) {
                resetTrainerIdFieldAndAlternativeEmailUI();

                var trimmedSearchTerm = event.target.value.trim();

                if (trimmedSearchTerm.length < 3) {
                    return false;
                }
            },
            select: function (event, ui) {
                updateTrainerIdFieldAndShowAlternativeEmailUI(ui.item);
            }
        });

        $(this).addInfoIconPopupWithContent(
            'Start typing to search for a trainer/assessor and select from the list. ' +
            'If you cannot find the trainer/assessor you\'re looking for, use the links alongside this tooltip to add them.'
        );

        $('#give-alternative-email').click(function (e) {
            e.preventDefault();

            $('#provide-alternative-email').hide();
            $('#use-default-email').show();
            $('#alternative-email').show();
        });

        $('#use-primary-email').click(function (e) {
            e.preventDefault();

            $('#provide-alternative-email').show();
            $('#use-default-email').hide();
            $('#alternative-email').hide();
            $trainerAlternativeEmailField.val('');
        });

        initForm();
        return this;
    };

    $.fn.trainerPickerWithoutAlternativeEmail = function(options) {

        if (!options) {
            options = {}
        }

        var autocompleterUrl;
        if (options.hasOwnProperty("autocompleterUrl")) {
            autocompleterUrl = options.autocompleterUrl;
        } else {
            autocompleterUrl = '/autocomplete/trainers';
        }

        var $trainerIdField = $('#' + $(this).attr('id') + 'Id');

        var resetTrainerIdField = function () {
            $trainerIdField.val('');
        };

        // force reset when the search field is completely blank
        $(this).focusout(function () {
            if ($(this).val() == '') {
                resetTrainerIdField();
            }
        });

        var updateTrainerIdField = function (trainerId) {
            var validEmployeeChosen = $.isNumeric(trainerId);
            if (! validEmployeeChosen) {
                resetTrainerIdField();
                return;
            }

            $trainerIdField.val(trainerId);
        };

        $(this).autocomplete({
            source: autocompleterUrl,
            minLength: 3,
            delay: 1000,
            search: function (event, ui) {
                resetTrainerIdField();

                var trimmedSearchTerm = event.target.value.trim();

                if (trimmedSearchTerm.length < 3) {
                    return false;
                }
            },
            select: function (event, ui) {
                updateTrainerIdField(ui.item.id);
            }
        });

        $(this).addInfoIconPopupWithContent('Start typing to search and select from the list.');
        return this;
    };
}(jQuery));