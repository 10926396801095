(function ($) {
    $.fn.allowRadioButtonReset = function (userOptions) {
        userOptions = userOptions || {};

        var options = {};

        $.extend(options, userOptions);

        return this.each(function () {
            var $this = $(this);
            var inputNamesApplied = [];

            $this.find('input[type="radio"]').each(function () {
                var $this = $(this);
                var inputName = $this.prop('name');

                if ($.inArray(inputName, inputNamesApplied) > -1) {
                    return;
                }

                inputNamesApplied.push(inputName);

                var $clearLink = $('<a/>')
                    .html('Clear Answer')
                    .prop('href', '#')
                    .prop('class', 'small faded')
                    .click(function (e) {
                        e.preventDefault();

                        $('input[name="'  + inputName + '"]').prop('checked', false).change();
                    })
                ;
                var $clearDiv = $('<div/>').html($clearLink);

                $this.closest('ul').after($clearDiv);
            });
        });
    };
})(jQuery);