(function ($) {
    $.fn.confirmClick = function (userOptions) {
        userOptions = userOptions || {};

        var options = {
            cancelCallback: function () {},
            cancelLabel: 'Cancel',
            confirmMessage: '',
            okLabel: 'OK'
        };

        $.extend(options, userOptions);

        var userHasConfirmedDataValue = 'config-action-has-confirmed';

        return this.each(function () {
            var $this = $(this);

            var confirmMessage = userOptions.confirmMessage || $this.data('confirm-click-message');

            $this.click(function (e) {
                if (! $this.data(userHasConfirmedDataValue)) {
                    e.preventDefault();

                    var confirmButtons = [{
                        label: options.okLabel,
                        callback: function () {
                            $this.data(userHasConfirmedDataValue, true);

                            // This must be called on the event, not on the jQuery object
                            e.currentTarget.click();
                        }
                    }, {
                        label: options.cancelLabel,
                        callback: options.cancelCallback
                    }];

                    var confirmDialog = new HORUS.dialog(confirmMessage, confirmButtons);

                    confirmDialog.show();
                }

                // allow the plugin to continue working after the first confirm click
                // e.g. if further validation failed, or for an ajax request that can be multiple times
                $this.data(userHasConfirmedDataValue, false);

            });
        });
    };
}(jQuery));