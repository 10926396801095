(function ($) {
    $.fn.confirmCancelChanges = function (dialogWording) {
        return this.each(function () {
            var $this = $(this);
            var dialog = new HORUS.dialog(dialogWording, [{
                label: 'OK',
                callback: function() {
                    window.location = $this.attr('href');
                }
            }, {
                label: 'Cancel',
                callback: function() { }
            }]);

            $this.click(function (e) {
                e.preventDefault();
                dialog.show();
            });
        });
    };
}(jQuery));