(function ($) {
    $.fn.uploadToAzureTemporaryBlob = function () {
        return this.each(function () {

            var $documentSelectorFileInputContainer = $(this);

            var runStage3VerifyFileOnAzure = function(sasUrl, uniquePathToTemporaryBlob, file) {
                $.ajax({
                    url: sasUrl,
                    type: 'HEAD',
                    success: function (data, textStatus, jqXHR) {
                        var contentLength = parseInt(jqXHR.getResponseHeader('content-length'), 10);
                        if (contentLength === file.size) {
                            $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadSucceeded', {
                                uniquePathToTemporaryBlob: uniquePathToTemporaryBlob
                            });
                        } else {
                            var errorCode = 'fileSizeMismatch[' + file.size.toString() + ',' + contentLength.toString() + ']';
                            $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadFailed', {
                                errors: [prepareErrorMessage(errorCode)]
                            });
                        }
                    }
                }).fail(function(jqXHR, textStatus, errorThrown) {
                    $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadFailed', {
                        errors: [prepareErrorMessage('verificationFailed')]
                    });
                });
            };

            var runStage2UploadFileViaSasUrl = function(sasUrl, uniquePathToTemporaryBlob, file) {
                $.ajax({
                    url: sasUrl,
                    type: "PUT",
                    data: file,
                    processData: false, // important
                    contentType: false, // important
                    headers: {
                        "x-ms-blob-type": "BlockBlob"
                    },
                    success: function (data, textStatus, jqXHR) {
                        runStage3VerifyFileOnAzure(sasUrl, uniquePathToTemporaryBlob, file);
                    }
                }).fail(function(jqXHR, textStatus, errorThrown) {
                    $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadFailed', {
                        errors: [prepareErrorMessage('uploadFailed')]
                    });
                });
            };

            var runStage1GetSasUrl = function (file) {
                $.ajax({
                    url: '/uploaded-evidence-section/temporary-upload-sas-settings',
                    type: 'POST',
                    data: {
                        fileName: file.name
                    },
                    success: function (response) {
                        var sasUrl = response.host + '/' + response.container + '/' + response.temporaryBlobUniquePath + response.sasQueryString;
                        runStage2UploadFileViaSasUrl(sasUrl, response.temporaryBlobUniquePath, file);
                    }
                }).fail(function(jqXHR, textStatus, errorThrown) {
                    $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadFailed', {
                        errors: [prepareErrorMessage('sasSettingsFailed')]
                    });
                });
            };

            var validate = function (file) {
                var errors = [];

                var validateFileSize = function (fileName, fileSize) {
                    var isPdf = fileName.match(/^.+\.pdf$/);
                    var sizeLimitMB = isPdf ? 4 : 1;
                    var bytesInOneMegabyte = 1048576;
                    return fileSize <= sizeLimitMB * bytesInOneMegabyte;
                };

                if (validateFileSize(file.name, file.size) === false) {
                    errors.push('The chosen files size exceeds the maximum limit, please see the upload tips section below.');
                }

                var validateFileNameLength = function (fileName) {
                    return fileName.length <= 75;
                };

                if (validateFileNameLength(file.name) === false) {
                    errors.push('The chosen files name is too long, please see the upload tips section below.');
                }

                var validateFileNameExtension = function (fileName) {
                    // allow one or more non dot characters, but must end with dot followed by any of listed extensions
                    var fileNameRegEx = /^[^.]+\.(jpg|jpeg|gif|png|rtf|doc|docx|ppt|pptx|pdf)$/;
                    return fileName.toLowerCase().match(fileNameRegEx) !== null;
                };

                if (validateFileNameExtension(file.name) === false) {
                    errors.push('The chosen files type is not on the list of accepted types, please see the upload tips section below.');
                }

                var validateFileNameCharacters = function (fileName) {
                    // check all characters before first dot, are alphanumeric, all characters after first dot, are not dots.
                    var fileNameRegEx = /^[0-9a-zA-Z-_ ]+\.[^.]+$/;
                    return fileName.match(fileNameRegEx) !== null;
                };

                if (validateFileNameCharacters(file.name) === false) {
                    errors.push('The chosen files name must only contain letters, numbers, spaces, dashes and underscores. Please see the upload tips section below.');
                }

                return errors;
            };

            // expects to be called on a div containing file inputs (this is so the file input can be cloned and is easily replaceable)
            $documentSelectorFileInputContainer.on('change', 'input[type=file]', function () {
                var $fileInput = $(this);
                // extra check that file has been selected to avoid IE bug where change event is called twice
                if ($fileInput.val()) {
                    var file = $fileInput[0].files[0];

                    var errors = validate(file);
                    if (errors.length > 0) {
                        $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadFailed', {errors: errors});
                        return;
                    }

                    $documentSelectorFileInputContainer.trigger('portfolioItemDocument:uploadInProgress');

                    runStage1GetSasUrl(file);
                }
            });

            var prepareErrorMessage = function (errorCode) {
                var errorTemplate = 'There was a problem uploading your file, please try again ' +
                    '(if this issue persists please contact support quoting error code "<% errorCode %>")';
                return Mustache.render(errorTemplate, {errorCode: errorCode});
            };

        });
    };
}(jQuery));