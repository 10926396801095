(function ($) {
    $.fn.paging = function (options) {

        if (!options.hasOwnProperty("dataUrl")) {
            alert('Data url is missing');
            return false;
        }

        if (!options.hasOwnProperty("columnDefs")) {
            options.columnDefs = {};
        }

        var $dataTableElement = $(this);
        var dataTableId = $(this).attr('id');
        var filterFormId = dataTableId + 'Filter';
        var $filterForm = $('#' + filterFormId);
        var filterLocalStorageKey = $filterForm.attr('name');
        var filterFormName = $filterForm.attr('name');

        var $jsFilterFieldsShow = $('#js-filter-fields-show').click(function (e) {
            e.preventDefault();

            var $filterFields = $('#js-filter-fields');

            var $filterButton = $('button[type=submit]', $(this).closest('.form-admin'));
            var $resetButton = $('button[type=reset]', $(this).closest('.form-admin'));

            if ($filterFields.is(':visible')) {
                $(this).before($filterButton);
                $('input[type=text]', $filterFields).val('');
                $('select option', $filterFields).prop('selected', false);

                var filters = getFilters();
                HORUS.localStorageSetItemIfUserGranted(filterLocalStorageKey, JSON.stringify(filters));
            } else {
                $resetButton.before($filterButton);
            }

            $filterFields.toggle();
        });

        $filterForm.submit(function (e) {
            e.preventDefault();

            var filters = getFilters();
            HORUS.localStorageSetItemIfUserGranted(filterLocalStorageKey, JSON.stringify(filters));
            dataTable.ajax.reload();
        });

        $('#' + filterFormName + '_reset').click(function (e) {
            e.preventDefault();

            $filterForm.get(0).reset();
            $filterForm.submit();
        });

        var filterFormStorageData = JSON.parse(localStorage.getItem(filterLocalStorageKey)) || {};

        if (!$.isEmptyObject(filterFormStorageData)) {
            setFilters(filterFormStorageData);
            if (hasAdvancedFilterFilled(filterFormStorageData)) {
                $jsFilterFieldsShow.click();
            }
        }

        var columnDefs = getColumnDefinitions(options.columnDefs);
        var ordering = getOrdering(options.columnDefs);
        var dataTable = $dataTableElement.DataTable({
            bFilter: false,
            ordering: ordering['isOn'],
            deferRender: true,
            columnDefs: columnDefs,
            processing: true,
            language: {
                processing: '<img src="/images/loading-indicator.gif"> Please wait, searching for matching entries...',
                emptyTable: 'No matching entries found, please try changing the parameters above.'
            },
            ajax: {
                url: options.dataUrl,
                complete: function (xhr) {
                    var selector = '#' + filterFormId + ' .form-field-error-list';
                    $(selector).remove();

                    var json = xhr.responseJSON;
                    if (json && json['filterErrors']) {
                        showFormErrors(json['filterErrors']);
                    }
                },
                data: function (data) {
                    data.filters = getFilters();
                    data.orderColumns = ordering['orderColumn'];

                    $('.js-datatables-errors').remove();
                },
                error: function () {
                    $('#' + dataTableId + '_processing').hide();
                    $dataTableElement.before('<ul class="error-messages js-datatables-errors"><li>Retrieving the data for the results table failed, please return to the home page before retrying.</li></ul>');
                }
            },
            serverSide: true,
            lengthMenu: [[10, 25, 50], [10, 25, 50]],
            deferLoading: 0,
            stateSave: HORUS.cookieManager.cookiePreferences.isLocalStorageGranted(),
            bAutoWidth: false
        });

        if (! HORUS.cookieManager.cookiePreferences.isLocalStorageGranted()) {
            $filterForm.before(HORUS.cookieManager.getLocalStorageDisabledMessageElement(true));
        }

        dataTable.ajax.reload(null, false);

        function getOrdering(userColumnDefs) {
            var ordering = [];
            ordering['isOn'] = false;
            ordering['orderColumn'] = [];

            var columnAmount = $('#' + dataTableId + ' thead tr th').length;
            for (var i = 0; i < columnAmount; i++) {
                if (userColumnDefs[i]) {
                    if (userColumnDefs[i].hasOwnProperty("orderable")) {
                        if (userColumnDefs[i].hasOwnProperty("orderColumnName")) {
                            ordering['isOn'] = true;
                            ordering['orderColumn'].push([userColumnDefs[i].targets, userColumnDefs[i].orderColumnName]);
                        } else {
                            alert('Missing order column name');
                        }
                    }
                }
            }
            return ordering;
        }

        function getColumnDefinitions(userColumnDefs) {
            var columnDefs = new Array();

            var columnAmount = $('#' + dataTableId + ' thead tr th').length;
            for (var i = 0; i < columnAmount; i++) {
                if (userColumnDefs[i]) {
                    var columnDefinition = {};
                    if (userColumnDefs[i].hasOwnProperty("targets")) {
                        columnDefinition.targets = userColumnDefs[i].targets;
                    } else {
                        alert('Missing targets parameter')
                    }

                    if (userColumnDefs[i].hasOwnProperty("className")) {
                        columnDefinition.className = userColumnDefs[i].className;
                    }

                    if (userColumnDefs[i].hasOwnProperty("orderable")) {
                        columnDefinition.orderable = userColumnDefs[i].orderable;
                    } else {
                        columnDefinition.orderable = false;
                    }

                    if (userColumnDefs[i].hasOwnProperty("render")) {
                        columnDefinition.render = userColumnDefs[i].render;
                    }

                    if (jQuery.isEmptyObject(columnDefinition) == false) {
                        columnDefinition.targets = i;
                        columnDefs.push(columnDefinition);
                    }
                }
            }
            return columnDefs;
        }

        function setFilters(filterFormStorageData) {
            for(var name in filterFormStorageData) {
                var value = filterFormStorageData[name];
                var selector = '#' + filterFormName +'_' + name;
                if($(selector).length) {
                    $(selector).val(value);
                }
            }
        }

        function getFilters() {
            var filters = {};

            var formData = $filterForm.serializeArray();
            for (var i = 0; i < formData.length; i++) {
                var regExp = /\[([^)]+)\]/;
                var matches = regExp.exec(formData[i]['name']);
                if (matches) {
                    var filterName = matches[1];
                } else {
                    var filterName = formData[i]['name'];
                }
                filters[filterName] = formData[i]['value'];
            }
            return filters;
        }
        
        function hasAdvancedFilterFilled(filterFormStorageData) {
            for(var name in filterFormStorageData) {
                var selector = '#js-filter-fields #' + filterFormName +'_' + name;
                if($(selector).length && $(selector).val()) {
                    return true;
                }
            }
            return false;
        }

        function showFormErrors(errors) {
            var template = '<ul class="form-field-error-list"><li><% errorMessage %></li></ul>';

            if (errors) {
                for (var key in errors){
                    if (errors.hasOwnProperty(key)) {
                        var view = {
                            'errorMessage': errors[key]
                        };
                        var html = Mustache.render(template, view);
                        $('#' + filterFormName + '_' + key).before(html);
                    }
                }
            }
        }

        return dataTable;
    };
}(jQuery));