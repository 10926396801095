(function ($) {
    $.fn.completePortfolioItem = function () {
        return this.each(function () {
            var $this = $(this);

            var dialogWording = 'Are you sure you wish to complete this form? ' +
                'Once completed you won\'t be able to make further changes.';

            $this.confirmClick({
                cancelCallback: function () {
                    $(this).protectClickTwiceReset();
                },
                confirmMessage: dialogWording,
                okLabel: 'Mark the form Complete'
            });
        });
    };
}(jQuery));