(function ($) {
    $.fn.protectClickTwice = function () {
        return $(this).each(function () {
            var $this = $(this);

            $this.click(function (e) {
                // Exit if button has click event already called on it, either by a click, trigger or JS event (like in
                // the confirmClick plugin)
                if ($this.hasClass('js-protect-click-twice-hidden')) {
                    return;
                }

                // exit if protection has been postponed by some other action (e.g. a js validation failure)
                if ($this.hasClass('js-protect-click-twice-postponed')) {
                    $this.removeClass('js-protect-click-twice-postponed');
                    return false;
                }

                var $disabledCopy = $this.clone();

                $this.addClass('js-protect-click-twice-hidden');
                $this.hide();

                $disabledCopy.addClass('js-protect-click-twice-disabled');
                $disabledCopy.click(function (e) {
                    e.preventDefault();
                });
                $disabledCopy.css('opacity', '0.4');

                $this.after($disabledCopy);
            });
        });
    };

    $.fn.protectClickTwiceReset = function () {
        $('.js-protect-click-twice-disabled').remove();
        $('.js-protect-click-twice-hidden').each(function () {
            $(this).removeClass('js-protect-click-twice-hidden');
            $(this).show();
        });
    };

}(jQuery));