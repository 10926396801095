(function ($) {
    $.fn.portfolioItemForm = function(options) {
        options = $.extend({}, {
            collectionFormData: [],
            otherCollectionSection: []
        }, options);

        var isInReadOnlyMode;
        var $form = this;
        var portfolioItemFormRunClass = 'portfolioItemForm--run';

        var initForm = function ($form) {
            if ($form.hasClass(portfolioItemFormRunClass)) {
                return;
            }

            $form.addClass(portfolioItemFormRunClass);

            isInReadOnlyMode = $form.attr('readonly');

            if (options.collectionFormData) {
                for (var collectionKey in options.collectionFormData) {
                    var collectionFormData = options.collectionFormData[collectionKey];
                    $(collectionFormData['selector']).formCollections(collectionFormData);
                }
            }

            makeFormReadOnly();
        };

        var makeFormReadOnly = function () {
            var fakeTextTemplate = '<div>' +
                '<span class="fake-field__text"><% content %><%^ content %><span class="portfolio-item__empty-answer">Empty</span><%/ content %></span>' +
                '</div>';
            var fakeTextareaTemplate =
                '<div class="fake-field__textarea">' +
                '<% content %><%^ content %><span class="portfolio-item__empty-answer">Empty</span><%/ content %>' +
                '</div>';
            var fakeSelectTemplate = '<div><span class="fake-field__select"><% content %><%^ content %><span class="portfolio-item__empty-answer">Empty</span><%/ content %><span class="caret portfolio-item__fake-input-select__caret"></span></span></div>';
            var fakeCheckboxTemplate = '<%# checked %><img src="' + HORUS.assetPathAbsolute + 'images/checkbox_checked.png"> <%/ checked %><%^ checked %><img src="' + HORUS.assetPathAbsolute + 'images/checkbox_unchecked.png"> <%/ checked %>';
            var noEntriesLiTemplate = '<li><span class="portfolio-item__empty-answer">No entries found</span></li>';
            var noEntriesDivTemplate = '<div><span class="portfolio-item__empty-answer">No entries found</span></div>';

            if (isInReadOnlyMode) {
                $form.find('input[type="file"]').parent().hide();
                $form.find('a.add-new-collection-form').parent().remove();
                $form.find('.remove-collection-form').remove();
                $form.find('[type="submit"]').remove();
                $form.find('.ac_input').next().remove();

                $form.find('textarea').each(function () {
                    var content = $(this).val();

                    $(this).after(Mustache.render(fakeTextareaTemplate, { content: content })).hide();
                });

                $form.find('input[type="text"], input[type="number"]').each(function () {
                    var content = $(this).val();

                    $(this).after(Mustache.render(fakeTextTemplate, { content: content })).hide();
                });

                $form.find('input[type="email"]').each(function () {
                    var content = $(this).val();

                    $(this).after(Mustache.render(fakeTextTemplate, { content: content })).hide();
                });

                $form.find('input[type="radio"], input[type="checkbox"]').each(function () {
                    var checked = $(this).is(':checked');

                    $(this).after(Mustache.render(fakeCheckboxTemplate, { checked: checked })).hide();
                });

                $form.find('select').each(function () {
                    var $this = $(this);
                    var multiple = $this.prop('multiple');
                    var contentArray = [];

                    if (multiple) {
                        $this.children('option:selected').each(function (index, element) {
                            contentArray.push($(element).text());
                        })
                    } else {
                        contentArray.push($this.children('option:selected').text());
                    }

                    var content = contentArray.join(', ');

                    $(this).after(Mustache.render(fakeSelectTemplate, { content: content })).hide();
                });

                for (var collectionKey in options.collectionFormData) {
                    var $collectionFormData = options.collectionFormData[collectionKey];
                    var $collectionHolder = $($collectionFormData.selector);

                    if ($collectionHolder.has('li').length == 0) {
                        $collectionHolder.append(Mustache.render(noEntriesLiTemplate));
                    }
                }

                $('.ui-autocomplete-input').parent().infoIconPopup('halt');
            }

            for (var otherCollectionKey in options.otherCollectionSection) {
                var $otherCollectionData = options.otherCollectionSection[otherCollectionKey];
                var $otherCollectionHolder = $($otherCollectionData.selector);

                if ($otherCollectionHolder.length == 0) {
                    if (isInReadOnlyMode) {
                        $otherCollectionHolder.append(Mustache.render(noEntriesDivTemplate));
                    } else {
                        $otherCollectionHolder.parent().remove();
                    }
                }
            }
        };

        initForm($form);

        // protect from accidental double clicks anywhere within the portfolio items section (must include all buttons such as mark complete, delete, revert to draft etc)
        $('button', '.portfolio-item').protectClickTwice();
    };
}(jQuery));