(function ($) {
    $.fn.traineePicker = function() {
        var $this = $(this);
        var $foundationDoctorIdHiddenField = $('#' + $this.attr('id') + 'Id');

        $this.autocomplete({
            source: '/autocomplete/trainees',
            minLength: 3,
            delay: 1000,
            search: function (event, ui) {
                if ($foundationDoctorIdHiddenField.length) {
                    $foundationDoctorIdHiddenField.val('');
                }

                var trimmedSearchTerm = event.target.value.trim();

                if (trimmedSearchTerm.length < 3) {
                    return false;
                }

                $this.trigger('traineePicker:search');
            },
            select: function (event, ui) {
                if ($foundationDoctorIdHiddenField.length) {
                    $foundationDoctorIdHiddenField.val(ui.item.id);
                }

                $this.trigger('traineePicker:select');
            }
        });

        $this.addInfoIconPopupWithContent('Start typing to search for a foundation doctor and select from the list.');

        return this;
    };
}(jQuery));