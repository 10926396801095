(function ($) {
    $.fn.curriculumMapper = function (userOptions) {
        userOptions = userOptions || {};

        var options = {
            horusRoutes: {}
        };

        $.extend(options, userOptions);

        var $mapperPlaceholder = $('.js-curriculum-mapper-placeholder');
        var $curriculumMapperStatus = $('.js-curriculum-mapper-status');
        var $curriculumMapperStatusClear = $('.js-curriculum-mapper-status-clear');
        var $curriculumMappingTitleSuffix = $('.js-curriculum-mapping-title-suffix');
        var evidenceMapperListTemplate = $('.js-evidence-mapper-template').html();
        var $mapperClose = $('.js-curriculum-mapper-close');

        $('.js-curriculum-mapper-help').on('click', function (e) {
            e.preventDefault();

            $('.js-curriculum-mapper-help-text').toggle();
        });

        var clearStatusMessages = function () {
            $curriculumMapperStatus.empty();
            $curriculumMapperStatusClear.hide();
        };

        var addStatusMessage = function (statusMessage) {
            var $statusItem = $('<div>').addClass('red small').html(statusMessage);
            $curriculumMapperStatus.append($statusItem);
            $curriculumMapperStatusClear.show();
        };

        $curriculumMapperStatusClear.click(function (e) {
            e.preventDefault();

            clearStatusMessages();
        });

        return this.each(function () {
            var $this = $(this);

            $this.click(function (e) {
                e.preventDefault();

                var $this = $(this);
                var curriculumMapperEvidenceType = $this.data('curriculum-mapper-evidence-type');
                var curriculumMapperEvidenceId = $this.data('curriculum-mapper-evidence-id');
                var $parentTr = $this.closest('.table-data tr');
                var evidenceMappingTemplateData = {};
                var mappedEvidenceIds = [];
                var mapEvidenceCsrfToken = null;
                var removeEvidenceCsrfToken = null;

                $('.table__row--highlight').removeClass('table__row--highlight');

                if ($parentTr.length) {
                    $parentTr.addClass('table__row--highlight');
                }

                var closeMapper = function () {
                    if ($parentTr.length) {
                        $parentTr.removeClass('table__row--highlight');
                    }

                    $('.js-curriculum-mapper-help-text').hide();

                    $mapperPlaceholder.hide();
                    $this.focus();
                };

                // Set up escape key press to close curriculum mapper
                $(document).keyup(function(e) {
                    if (e.key === "Escape") {
                        closeMapper();
                    }
                });

                var moveFocusBackToClose = function ($curriculumMapperParentLinks, $curriculumMapperNodeIdLinks) {
                    $($curriculumMapperParentLinks).last().keydown(function (e) {
                        var $this = $(this);
                        if (e.key === 'Tab') {
                            var isExpanded = $this.parent().hasClass('curriculum-mapper__item-list-title--active');
                            if (! isExpanded) {
                                $mapperClose.focus();
                                e.preventDefault();
                            }
                        }
                    })
                    $($curriculumMapperNodeIdLinks).last().keydown(function (e) {
                        if (e.key === 'Tab') {
                            $mapperClose.focus();
                            e.preventDefault();
                        }
                    })
                };

                $('#js-curriculum-mapping-list-reload-required-warning').show();

                $mapperPlaceholder
                    .data('curriculum-mapper-evidence-type', curriculumMapperEvidenceType)
                    .data('curriculum-mapper-evidence-id', curriculumMapperEvidenceId)
                    .hide();

                $('.js-curriculum-mapper-help-text').hide();

                function populateMappedEvidenceIds(curriculumItem) {
                    if (curriculumItem.mapped) {
                        mappedEvidenceIds.push({
                            nodeId: curriculumItem.nodeId,
                            evidenceMappingId: curriculumItem.evidenceMappingId
                        });
                    }

                    if (curriculumItem.nodeChildren.length > 0) {
                        for (var i = 0; i < curriculumItem.nodeChildren.length; i++) {
                            populateMappedEvidenceIds(curriculumItem.nodeChildren[i]);
                        }
                    }
                }

                var showEvidenceMappingUrl = decodeURI(options.horusRoutes.curriculumShowEvidenceMapping)
                    .replace(/\{evidenceType\}|\{evidenceId\}/gi, function (matched) {
                        var matchMap = {
                            '{evidenceType}': curriculumMapperEvidenceType,
                            '{evidenceId}': curriculumMapperEvidenceId
                        };

                        return matchMap[matched];
                    });

                $mapperClose.off('click.curriculum-mapper').on('click.curriculum-mapper', function (e) {
                    e.preventDefault();
                    closeMapper();
                });

                $.post(showEvidenceMappingUrl, function (responseData) {
                    if (responseData.title) {
                        $curriculumMappingTitleSuffix.text(': ' + responseData.title);
                    } else {
                        $curriculumMappingTitleSuffix.text('');
                    }

                    if (responseData.status == 'OK') {
                        clearStatusMessages();

                        mapEvidenceCsrfToken = responseData.csrfToken.mapEvidence;
                        removeEvidenceCsrfToken = responseData.csrfToken.removeEvidence;

                        for (var i = 0; i < responseData.structure.length; i++) {
                            populateMappedEvidenceIds(responseData.structure[i]);
                        }

                        evidenceMappingTemplateData.nodeChildren = responseData.structure;

                        var evidenceMapperListTemplateHtml = Mustache.render(evidenceMapperListTemplate, evidenceMappingTemplateData, {
                            recurse: evidenceMapperListTemplate
                        });

                        var $evidenceMapperList = $(evidenceMapperListTemplateHtml);

                        $('.js-curriculum-mapper-list-wrapper').html('');
                        $evidenceMapperList.appendTo('.js-curriculum-mapper-list-wrapper');

                        $evidenceMapperList.find('.js-curriculum-mapper-parent-link').click(function (e) {
                            e.preventDefault();

                            var $this = $(this);
                            var $nodeTitle = $this.parent();
                            var $nodeChildList = $nodeTitle.siblings('.curriculum-mapper__item-list');
                            var $parentChildLists = $nodeChildList.parents('.curriculum-mapper__item-list');

                            $('.curriculum-mapper__item-list-title').not($nodeTitle)
                                .removeClass('curriculum-mapper__item-list-title--active');
                            $('.curriculum-mapper__item-list__item .curriculum-mapper__item-list')
                                .not($nodeChildList).not($parentChildLists).slideUp('fast');

                            $nodeTitle.toggleClass('curriculum-mapper__item-list-title--active');
                            $nodeChildList.slideToggle('fast');
                        });

                        var $curriculumMapperNodeIdLinks = $evidenceMapperList.find('.js-curriculum-mapper-item-id-link');

                        $curriculumMapperNodeIdLinks.on('evidence-mapper:create-mapping-highlight', function (e) {
                            $(this).parent().addClass('curriculum-mapper__item-id-title--active');
                        });

                        $curriculumMapperNodeIdLinks.on('evidence-mapper:remove-mapping-highlight', function (e) {
                            $(this).parent().removeClass('curriculum-mapper__item-id-title--active');
                        });

                        var highlightEventData = {
                            'evidenceId': curriculumMapperEvidenceId
                        };

                        $curriculumMapperNodeIdLinks.click(function (e) {
                            e.preventDefault();

                            var $this = $(this);
                            var postData = {};
                            var action;
                            var actionType;

                            if ($this.data('evidence-mapper-item-id-link-click-active')) {
                                return;
                            }

                            $this.data('evidence-mapper-item-id-link-click-active', true)
                                .addClass('curriculum-mapper__item-id-link--inactive');

                            if ($this.data('evidence-mapping-id')) {
                                postData.evidenceMappingId = $this.data('evidence-mapping-id');
                                postData._token = removeEvidenceCsrfToken;

                                action = options['horusRoutes']['curriculumRemoveEvidence'];
                                actionType = 'delete';

                                $this.trigger('evidence-mapper:remove-mapping-highlight', [highlightEventData]);
                            } else {
                                postData.evidenceType = curriculumMapperEvidenceType;
                                postData.evidenceId = curriculumMapperEvidenceId;
                                postData.curriculumItemId = $this.data('item-id');
                                postData.curriculumItemLabel = $this.parent().text();
                                postData._token = mapEvidenceCsrfToken;

                                action = options['horusRoutes']['curriculumMapEvidence'];
                                actionType = 'create';

                                $this.trigger('evidence-mapper:create-mapping-highlight', [highlightEventData]);
                            }

                            var $curriculumMapperNodeIdLink = $this;

                            $.post(action, postData, function (responseData) {
                                if (responseData['statusMessage']) {
                                    addStatusMessage(responseData['statusMessage']);
                                }

                                $curriculumMapperNodeIdLink.data('evidence-mapping-id', responseData['evidenceMappingId']);

                                if (responseData['status'] == 0) {
                                    if (actionType == 'create') {
                                        $curriculumMapperNodeIdLink.trigger('evidence-mapper:remove-mapping-highlight', [highlightEventData]);
                                    } else {
                                        $curriculumMapperNodeIdLink.trigger('evidence-mapper:create-mapping-highlight', [highlightEventData]);
                                    }
                                }
                            }).fail(function () {
                                if (actionType == 'create') {
                                    $curriculumMapperNodeIdLink.trigger('evidence-mapper:remove-mapping-highlight', [highlightEventData]);
                                } else {
                                    $curriculumMapperNodeIdLink.trigger('evidence-mapper:create-mapping-highlight', [highlightEventData]);
                                }
                            }).always(function () {
                                $curriculumMapperNodeIdLink.data('evidence-mapper-item-id-link-click-active', false)
                                    .removeClass('curriculum-mapper__item-id-link--inactive');
                            });

                        });

                        for (var k = 0; k < mappedEvidenceIds.length; k++) {
                            $curriculumMapperNodeIdLinks.filter('[data-item-id="' + mappedEvidenceIds[k].nodeId + '"]')
                                .data('evidence-mapping-id', mappedEvidenceIds[k].evidenceMappingId)
                                .trigger('evidence-mapper:create-mapping-highlight', [highlightEventData]);
                        }
                    } else {
                        $curriculumMapperStatus.text('Evidence mapping is not allowed, you may not have the correct permissions, or the rotation/curriculum may no longer be active.');
                        $('.js-curriculum-mapper-list-wrapper').html('');
                    }
                    $mapperPlaceholder.show();

                    $mapperClose.focus();

                    var $curriculumMapperParentLinks = $evidenceMapperList.find('.js-curriculum-mapper-parent-link')
                    moveFocusBackToClose($curriculumMapperParentLinks, $curriculumMapperNodeIdLinks);
                });
            });
        });
    };
}(jQuery));