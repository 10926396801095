(function ($) {
    $.fn.formCollections = function (options) {
        var newCollectionItemLinkClass = 'js-portfolio-item-form-new-form-link';

        options = $.extend({}, {
            collectionFormLimit: 5,
            linkText: 'Add new',
            autocompleterUrl: '',
            makeDependantOn: [],
            handleCollectionItemRemove: true
        }, options);

        function autoCompletersForCollection($collectionItem) {
            var elements = $collectionItem.find('.trainerPickerWithoutAlternativeEmail');

            for (var i = 0; i < elements.length; i++) {
                var $element = $(elements[i]);

                if (! $element.attr('disabled')) {
                    $element.trainerPickerWithoutAlternativeEmail({'autocompleterUrl': options.autocompleterUrl});
                }
            }
        }

        function makeDependantOn($collectionItem) {
            if (! options.makeDependantOn.length) {
                return;
            }

            for (var collectionKey in options.makeDependantOn) {
                var makeDependantOnData = options.makeDependantOn[collectionKey];

                var $inputToDependOn = $collectionItem.find('[id$="' + makeDependantOnData.fieldName + '"]');
                var $dependantInput = $collectionItem.find('.' + makeDependantOnData.makeDependantOnClass);

                $($dependantInput).makeDependantOn({
                    'propertyName': $inputToDependOn.attr('name'),
                    'showSectionValues': makeDependantOnData.showSectionValues
                });
            }
        }

        function addCollectionItem($collectionHolder, $addNewCollectionItemLi) {
            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // Try to guess the next index from the number of forms in the page currently
            var nextIndex = $collectionHolder.triggerHandler('formCollections:collectionItemCount');

            // If there is any element with the name parameter containing the current next index,
            // increment until we have a unique index
            while ($collectionHolder.find('[name*="[' + nextIndex + ']"]').length > 0) {
                nextIndex++;
            }

            // Replace '__name__' in the prototype's HTML to instead be a number based on how many items
            // we have
            var newCollectionItem = prototype.replace(/__name__/g, nextIndex);

            // Display the form in the page in an li, before the "Add a person" link li
            var $newCollectionItemLi = $('<li />').addClass('expanded-choice__item').append(newCollectionItem);
            $addNewCollectionItemLi.before($newCollectionItemLi);

            addCollectionItemRemoveLink($collectionHolder, $newCollectionItemLi);

            return $newCollectionItemLi;
        }

        function addCollectionItemRemoveLink($collectionHolder, $collectionItem) {
            var $collectionItemRemoveLink = $('<a href="#" class="remove-collection-form__link">Remove the item above</a>');

            var $collectionItemRemoveLinkWrapper = $collectionItemRemoveLink.wrap('<div class="remove-collection-form"></div>');

            $collectionItem.append($collectionItemRemoveLinkWrapper);

            $collectionItemRemoveLink.click(function (e) {
                e.preventDefault();

                if (options.handleCollectionItemRemove) {
                    $collectionItem.remove();

                    $collectionHolder.trigger('formCollections:showHideAddNewCollectionItemLink');
                }
            });
        }

        return this.each(function () {
            var $collectionHolder = $(this);

            var $addNewCollectionItemLink = $('<a />').attr('href', '#').addClass('add-new-collection-form').text(options.linkText);
            var $addNewCollectionItemLi = $('<li />').addClass(newCollectionItemLinkClass).append($addNewCollectionItemLink);

            $collectionHolder.append($addNewCollectionItemLi);

            // Call this with $elem.triggerHandler as it returns data
            $collectionHolder.on('formCollections:collectionItemCount', function () {
                // We can't use $collectionItems here as this is a cached results. We want to keep querying the collection
                // holder to ensure the count is accurate.
                return $collectionHolder.children('li').not('.' + newCollectionItemLinkClass).length;
            });

            $collectionHolder.on('formCollections:showHideAddNewCollectionItemLink', function () {
                if ($collectionHolder.triggerHandler('formCollections:collectionItemCount') < options.collectionFormLimit) {
                    $addNewCollectionItemLink.show();
                } else {
                    $addNewCollectionItemLink.hide();
                }
            });

            $collectionHolder.trigger('formCollections:showHideAddNewCollectionItemLink');

            $collectionHolder.on('formCollections:addDatePickers', function () {
                $('.js-collection-datepicker', $collectionHolder).datepicker({
                    dateFormat: 'dd-M-yy'
                }).removeClass('js-collection-datepicker').attr('autocomplete', 'off');
            });

            $collectionHolder.trigger('formCollections:addDatePickers');

            var $collectionItems = $collectionHolder.children('li').not('.' + newCollectionItemLinkClass);

            $collectionItems.each(function () {
                var $collectionItem = $(this);

                addCollectionItemRemoveLink($collectionHolder, $collectionItem);
                autoCompletersForCollection($collectionItem);
                makeDependantOn($collectionItem);
            });

            $addNewCollectionItemLink.click(function (e) {
                e.preventDefault();

                var $newCollectionItem = addCollectionItem($collectionHolder, $addNewCollectionItemLi);

                $collectionHolder.trigger('formCollections:showHideAddNewCollectionItemLink');
                $collectionHolder.trigger('formCollections:addDatePickers');

                autoCompletersForCollection($newCollectionItem);
                makeDependantOn($newCollectionItem);
            });
        });
    }
}(jQuery));