(function ($) {
    $.fn.tabConcernWarning = function () {
        var userHasConfirmedDataValue = 'config-action-has-confirmed';

        return this.each(function () {
            $(this).click(function (e) {
                var $clickTarget = $(this);

                if ($clickTarget.data(userHasConfirmedDataValue)) {
                    return true;
                }

                var relationshipsVal = $('input[name$="[relationships]"]:checked').val();
                var communicationVal = $('input[name$="[communication]"]:checked').val();
                var colleaguesVal = $('input[name$="[colleagues]"]:checked').val();
                var accessibilityVal = $('input[name$="[accessibility]"]:checked').val();

                var someConcerns = [];

                if (relationshipsVal === 'some-concern') {
                    someConcerns.push('Relationships');
                }

                if (communicationVal === 'some-concern') {
                    someConcerns.push('Communication');
                }

                if (colleaguesVal === 'some-concern') {
                    someConcerns.push('Team working');
                }

                if (accessibilityVal === 'some-concern') {
                    someConcerns.push('Accessibility');
                }

                var majorConcerns = [];

                if (relationshipsVal === 'major-concern') {
                    majorConcerns.push('Relationships');
                }

                if (communicationVal === 'major-concern') {
                    majorConcerns.push('Communication');
                }

                if (colleaguesVal === 'major-concern') {
                    majorConcerns.push('Team working');
                }

                if (accessibilityVal === 'major-concern') {
                    majorConcerns.push('Accessibility');
                }

                if (! majorConcerns.length && ! someConcerns.length) {
                    return true;
                }

                e.preventDefault();

                var confirmMessage = 'Please confirm that you intended to select the following concerns in this form:';

                if (majorConcerns.length) {
                    confirmMessage += "\n\n" + 'MAJOR CONCERN:' + "\n";
                    confirmMessage += '- ' + majorConcerns.join("\n- ");
                }

                if (someConcerns.length) {
                    confirmMessage += "\n\n" + 'SOME CONCERN:' + "\n";
                    confirmMessage += '- ' + someConcerns.join("\n- ");
                }

                var confirmButtons = [{
                    label: 'Yes, keep selections',
                    callback: function () {
                        $clickTarget.data(userHasConfirmedDataValue, true);

                        // This must be called on the event, not on the jQuery object
                        e.currentTarget.click();
                    }
                }, {
                    label: 'No, change selections',
                    callback: function () {
                        $clickTarget.protectClickTwiceReset();
                    }
                }];

                var confirmDialog = new HORUS.dialog(confirmMessage, confirmButtons, {wideBody: true});

                confirmDialog.show();
            });
        });
    };
}(jQuery));