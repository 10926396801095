(function ($) {
    $.fn.indicateEditedFields = function (userOptions) {
        userOptions = userOptions || {};

        var options = {
            cssIndicatorClass: 'indicate-edited-field',
            textSelectors: ['input[type="text"]'],
            customSelectorsAndDefaultValues: [],
        };

        $.extend(options, userOptions);

        return this.each(function () {
            var $this = $(this);

            $.each(options.textSelectors, function (index, value) {
                $(value, $this).on('change', function() {
                    var $this = $(this);
                    var value = $.trim($this.val());
                    $this.toggleClass(options.cssIndicatorClass, value.length !== 0);
                }).change();
            });

            $.each(options.customSelectorsAndDefaultValues, function (index, value) {
                $(value.selector, $this).on('change', function() {
                    var $this = $(this);
                    $this.toggleClass(options.cssIndicatorClass, value.defaultValue !== $this.val());
                }).change();
            });

            // Handle the use of the form reset button (force a change event after the reset event)
            $this.on('reset', function () {
                $.each(options.textSelectors, function (index, value) {
                    // hack to ensure change fires after the reset has cleared the form
                    setTimeout(function() {
                        $(value, $this).change();
                    }, 1);
                });

                $.each(options.customSelectorsAndDefaultValues, function (index, value) {
                    // hack to ensure change fires after the reset has cleared the form
                    setTimeout(function() {
                        $(value.selector, $this).change();
                    }, 1);
                });
            });
        });
    };
}(jQuery));