(function ($) {
    $.fn.psgConcernWarning = function () {
        var userHasConfirmedDataValue = 'config-action-has-confirmed';

        return this.each(function () {
            $(this).click(function (e) {
                var $clickTarget = $(this);

                if ($clickTarget.data(userHasConfirmedDataValue)) {
                    return true;
                }

                var fields = {
                    // Curriculum 2016
                    'performanceProfessional': 'S1: Professional behaviour and trust',
                    'performanceProfessionalFpc1': '1. Acts professionally',
                    'performanceProfessionalFpc2': '2. Delivers patient centred care and maintains trust',
                    'performanceProfessionalFpc3': '3. Behaves in accordance with ethical and legal requirements',
                    'performanceProfessionalFpc4': '4. Keeps practice up to date through learning and teaching',
                    'performanceProfessionalFpc5': '5. Demonstrates engagement in career planning',
                    'performanceCommunication': 'S2: Communication, teamworking and leadership',
                    'performanceCommunicationFpc6': '6. Communicates clearly in a variety of settings',
                    'performanceCommunicationFpc7': '7. Works effectively as a team member',
                    'performanceCommunicationFpc8': '8. Demonstrates leadership skills',
                    'performanceClinical': 'S3: Clinical care',
                    'performanceClinicalFpc9': '9. Recognises, assesses and initiates management of the acutely ill patient',
                    'performanceClinicalFpc10': '10. Recognises, assesses and manages patients with long term conditions',
                    'performanceClinicalFpc11': '11. Obtains history, performs clinical examination, formulates differential diagnosis and management plan',
                    'performanceClinicalFpc12': '12. Requests relevant investigations and acts upon results',
                    'performanceClinicalFpc13': '13. Prescribes safely',
                    'performanceClinicalFpc14': '14. Performs procedures safely',
                    'performanceClinicalFpc15': '15. Is trained and manages cardiac and respiratory arrest',
                    'performanceClinicalFpc16': '16. Demonstrates understanding of the principles of health promotion and illness prevention',
                    'performanceClinicalFpc17': '17. Manages palliative and end of life care',
                    'performanceSafety': 'S4: Safety and quality',
                    'performanceSafetyFpc18': '18. Recognises and works within limits of personal competence',
                    'performanceSafetyFpc19': '19. Makes patient safety a priority in clinical practice',
                    'performanceSafetyFpc20': '20. Contributes to quality improvement',

                    // Curriculum 2021
                    'hlo1': 'HLO 1. An accountable, capable and compassionate doctor',
                    'fpc1': 'FPC 1. Clinical Assessment',
                    'fpc2': 'FPC 2. Clinical Prioritisation',
                    'fpc3': 'FPC 3. Holistic Planning',
                    'fpc4': 'FPC 4. Communication and Care',
                    'fpc5': 'FPC 5. Continuity of Care',
                    'hlo2': 'HLO 2. A valuable member of the healthcare workforce',
                    'fpc6': 'FPC 6. Sharing the Vision',
                    'fpc7': 'FPC 7. Fitness to Practice',
                    'fpc8': 'FPC 8. Upholding Values',
                    'fpc9': 'FPC 9. Quality Improvement',
                    'fpc10': 'FPC 10. Teaching the Teacher',
                    'hlo3': 'HLO 3. A professional responsible for their own practice and portfolio development',
                    'fpc11': 'FPC 11. Ethics and Law',
                    'fpc12': 'FPC 12. Continuing Professional Development',
                    'fpc13': 'FPC 13. Understanding Medicine',
                };

                var someConcerns = [];
                var majorConcerns = [];

                for (var fieldName in fields) {
                    var fieldVal = $('input[name$="[' + fieldName + ']"]:checked:visible').val();

                    if (fieldVal === 'some-concern') {
                        someConcerns.push(fields[fieldName]);
                    }

                    if (fieldVal === 'major-concern') {
                        majorConcerns.push(fields[fieldName]);
                    }
                }

                if (! majorConcerns.length && ! someConcerns.length) {
                    return true;
                }

                e.preventDefault();

                var confirmMessage = 'Please confirm that you intended to select the following concerns in this form:';

                if (majorConcerns.length) {
                    confirmMessage += "\n\n" + 'MAJOR CONCERN:' + "\n";
                    confirmMessage += '- ' + majorConcerns.join("\n- ");
                }

                if (someConcerns.length) {
                    confirmMessage += "\n\n" + 'SOME CONCERN:' + "\n";
                    confirmMessage += '- ' + someConcerns.join("\n- ");
                }

                var confirmButtons = [{
                    label: 'Yes, keep selections',
                    callback: function () {
                        $clickTarget.data(userHasConfirmedDataValue, true);

                        // This must be called on the event, not on the jQuery object
                        e.currentTarget.click();
                    }
                }, {
                    label: 'No, change selections',
                    callback: function () {
                        $clickTarget.protectClickTwiceReset();
                    }
                }];

                var confirmDialog = new HORUS.dialog(confirmMessage, confirmButtons, {wideBody: true});

                confirmDialog.show();
            });
        });
    };
}(jQuery));