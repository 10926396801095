(function ($) {
    $.fn.supervisorFilter = function () {
        var that = $(this);
        var init = function () {
            toggleFilterType();
            that.click(function () {
                toggleFilterType();
                return false;
            })
        };

        var showHideFormSection = function () {
            var filterType = $('#list_supervisors_filterType').val();
            if (filterType == 'academicYear') {
                $('#list_supervisors_academicYearId').show();
                $('#list_supervisors_beginDate').hide();
                $('#list_supervisors_endDate').hide();
                that.html('Search by dates');
            } else {
                $('#list_supervisors_academicYearId').hide();
                $('#list_supervisors_beginDate').show();
                $('#list_supervisors_endDate').show();
                that.html('Search by training year');
            }
        };

        var toggleFilterType = function () {
            var filterType = $('#list_supervisors_filterType').val();
            if (filterType == 'academicYear') {
                $('#list_supervisors_filterType').val('dates');
            } else {
                $('#list_supervisors_filterType').val('academicYear');
            }
            showHideFormSection();
        };
        init();
    };
}(jQuery));