(function ($) {
    $.fn.bulkOperationsCounter = function (options) {

        var $dataTableElement = $(this);
        var $elementToPlaceNotificationAfter = options.elementToPlaceNotificationAfter;
        var dataTableId = $(this).attr('id');
        var bulkOperationContainerId = dataTableId + 'BulkOperation';
        var html = '<div id="' + bulkOperationContainerId + '" class="dataTables_bulkOperationsCounter">Items selected: <span class="amount">0</span></div>';

        function countChecked()
        {
            var amount = $dataTableElement.find('input:checkbox:checked').length;
            $('#' + bulkOperationContainerId + ' .amount').text(amount);
        }

        function init() {
            $elementToPlaceNotificationAfter.after(html);
            $dataTableElement.on( "change", "input[type='checkbox']", function() {
                countChecked();
            });
            $dataTableElement.on('draw.dt', function () {
                countChecked();
            });
        }
        init();
    };
}(jQuery));