(function ($) {
    $.fn.modal = function () {
        return this.each(function () {
            var $this = $(this);
            var $modal = $('#' + $this.data('target-id'));
            var $closeAnchor = $('<a />').addClass('modal__close-link').text('close').attr('href', '#');
            var $modalWrapperDiv = $('<div />').addClass('modal__wrapper');

            $modal.data('is-shown', false);
            $modal.attr('role', 'dialog');
            $modal.wrap($modalWrapperDiv);

            var $modalWrapper = $modal.parent();

            $(document).keydown(function(e) {
                if ($modal.data('is-shown') && e.key === 'Escape') {
                    $this.trigger('modal:hide', [$modal]);
                }
            });

            $(document).click(function (e) {
                var $target = $(e.target);

                if ($modal.data('is-shown') && $target.get(0) !== $this.get(0) && ! $target.closest($modal).length) {
                    $this.trigger('modal:hide', [$modal]);
                }
            });

            $closeAnchor.click(function (e) {
                e.preventDefault();

                $this.trigger('modal:hide', [$modal]);
            });

            $modal.append($closeAnchor);

            var focusableElements = $modal.find(
                'a, input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])'
            );

            var focusableElementsLastIndex = focusableElements.length - 1;

            $(document).keydown('keydown', function(e) {
                if (! $modal.data('is-shown')) {
                    return;
                }

                if (e.key === 'Tab') {
                    var focusedItemIndex = Array.from(focusableElements).indexOf(document.activeElement);

                    if (! e.shiftKey && focusedItemIndex === focusableElementsLastIndex) {
                        focusableElements.get(0).focus();

                        e.preventDefault();
                    }

                    if (e.shiftKey && focusedItemIndex === 0) {
                        focusableElements.get(focusableElementsLastIndex).focus();

                        e.preventDefault();
                    }
                }
            });

            $this.on('modal:hide', function () {
                $modalWrapper.hide();
                $modal.removeClass('modal--show');
                $modal.data('is-shown', false);
                $this.focus();
            });

            $this.on('modal:show', function () {
                $modalWrapper.show();
                $modal.addClass('modal--show');
                $modal.data('is-shown', true);

                $closeAnchor.focus();
            });

            $this.click(function (e) {
                e.preventDefault();

                $this.trigger('modal:show', [$modal]);
            })
        });
    };
}(jQuery));