(function ($) {
    $.fn.sendToTABAssessors = function () {
        return this.each(function () {
            var $this = $(this);

            var dialogWording = 'This will mark your Self TAB as complete, no further changes will be allowed. ' +
                'An email will be sent to each nominated assessor, you can still add more assessors while the TAB ' +
                'is running.';

            $this.confirmClick({
                cancelCallback: function () {
                    $(this).protectClickTwiceReset();
                },
                confirmMessage: dialogWording,
                okLabel: 'Send to TAB Assessors'
            });
        });
    };
}(jQuery));