(function ($) {
    $.fn.addPermissionsForEmployeeForm = function () {
        var $info = $('.add-permissions-to-organisation__info');
        var $errors = $('.add-permissions-to-organisation__errors');

        function getAdministrativePermissionsHtml(index, allowedPermissionsList) {
            var administrationPermissionsHtml = '<div class="permissions-section">';
            administrationPermissionsHtml += '<ul class="expanded-choice">';

            var optionHtml;
            var optionData;
            var permissionsAssigned = 0;

            $.each(allowedPermissionsList.administration, function (key, permissionData) {
                if (permissionData['available']) {
                    optionHtml = '<li class="expanded-choice__item">' +
                        '<span style="display: inline-block; width: 20px;">' +
                        '<input type="radio" id="<% input_id %>_<% index %>" name="<% input_name %>[permissions][]" value="<% value %>" <% checked %>>' +
                        '</span>' +
                        '<label id="label_<% input_id %>_<% index %>" for="<% input_id %>_<% index %>"><% text %></label>' +
                        '<div data-target-id="label_<% input_id %>_<% index %>" class="js-info-icon-popup"><% description %></div>' +
                        '</li>';

                    optionData = {
                        input_name: "grant_employee_permissions_to_organisation",
                        input_id: "grant_employee_permissions_to_organisation_permissions",
                        index: index,
                        value: permissionData['permissionIdentifier'],
                        text: permissionData['permissionName'],
                        description: permissionData['permissionDescription'],
                        checked: ''
                    };

                    if (permissionData['assigned']) {
                        optionData.checked = 'checked';
                        permissionsAssigned++;
                    }
                } else {
                    var reason;

                    if (permissionData['lockedByChild'] || permissionData['inherited']) {
                        reason = 'Already ' + permissionData['originPermission'] + ' at ' + permissionData['originOrganisation'];
                        optionHtml = '<li class="expanded-choice__item">' +
                            '<span style="display: inline-block; width: 20px;">' +
                            '<i class= "material-icons material-icons--small" style="cursor: help; color: gray;" title="<% reason %>">block</i>' +
                            '</span>' +
                            '<label id="label_<% input_id %>_<% index %>"><% text %></label>' +
                            '<div data-target-id="label_<% input_id %>_<% index %>" class="js-info-icon-popup"><% description %></div>' +
                            '</li>';
                    } else {
                        reason = 'Permission not available for this organisation';
                        optionHtml = '<li class="expanded-choice__item">' +
                            '<span style="display: inline-block; width: 20px; color: gray; font-size: smaller; cursor: help;" title="<% reason %>">n/a</span>' +
                            '<label id="label_<% input_id %>_<% index %>"><% text %></label>' +
                            '<div data-target-id="label_<% input_id %>_<% index %>" class="js-info-icon-popup"><% description %></div>' +
                            '</li>';
                    }

                    optionData = {
                        input_id: "grant_employee_permissions_to_organisation_permissions",
                        index: index,
                        reason: reason,
                        text: permissionData['permissionName'],
                        description: permissionData['permissionDescription']
                    };
                }

                administrationPermissionsHtml += Mustache.render(optionHtml, optionData);

                index++;
            });

            if (permissionsAssigned) {
                optionHtml = '<li class="expanded-choice__item">' +
                    '<span style="display: inline-block; width: 20px;">' +
                    '<input type="radio" id="<% input_id %>_revoke" name="<% input_name %>[permissions][]" value="<% value %>">' +
                    '</span>' +
                    '<label for="<% input_id %>_revoke" style="font-weight: bold;"><% text %></label>' +
                    '</li>'

                optionData = {
                    input_name: "grant_employee_permissions_to_organisation",
                    input_id: "grant_employee_permissions_to_organisation_permissions",
                    value: 'REVOKE_ADMINISTRATIVE_PERMISSION',
                    text: 'Revoke permission'
                };

                administrationPermissionsHtml += Mustache.render(optionHtml, optionData);
            }

            administrationPermissionsHtml += '</ul>';
            administrationPermissionsHtml += '</div>';

            return administrationPermissionsHtml;
        }

        function getAdditionalPermissionsHtml(index, allowedPermissionsList) {
            var additionalPermissionsHtml = '<div class="permissions-section">';
            additionalPermissionsHtml += '<ul class="expanded-choice">';

            $.each(allowedPermissionsList.additional, function (key, permissionData) {
                if (permissionData['available']) {
                    var permissionHtmlTemplate = '<li class="expanded-choice__item">' +
                        '<span style="display: inline-block; width: 20px;">' +
                        '<input type="checkbox" id="<% input_id %>_<% index %>" name="<% input_name %>[permissions][]" value="<% value %>" <% checked %> <% disabled %>>' +
                        '</span>' +
                        '<label id="label_<% input_id %>_<% index %>" for="<% input_id %>_<% index %>"><% text %></label>' +
                        '<div data-target-id="label_<% input_id %>_<% index %>" class="js-info-icon-popup"><% description %></div>' +
                        '<%&additionalHtml %>' +
                        '</li>';

                    var permissionOptions = {
                        input_name: "grant_employee_permissions_to_organisation",
                        input_id: "grant_employee_permissions_to_organisation_permissions",
                        index: index,
                        value: permissionData['permissionIdentifier'],
                        text: permissionData['permissionName'],
                        description: permissionData['permissionDescription'],
                        checked: '',
                        disabled: '',
                        additionalHtml: ''
                    };

                    if (permissionData['assigned']) {
                        permissionOptions.checked = 'checked';
                    }

                    if (permissionData['inherited']) {
                        var additionalOptions = {
                            origin_permission: permissionData['originPermission'],
                            origin_organisation: permissionData['originOrganisation'],
                        };
                        var additionalHtmlTemplate = '<div class="permissions-section__note">Already <% origin_permission %> at <% origin_organisation %></div>';
                        permissionOptions.additionalHtml = Mustache.render(additionalHtmlTemplate, additionalOptions);
                    }
                } else {
                    var permissionHtmlTemplate = '<li class="expanded-choice__item">' +
                        '<span style="display: inline-block; width: 20px; color: gray; font-size: smaller; cursor: help;" title="Permission not available for this organisation">n/a</span>' +
                        '<label id="label_<% input_id %>_<% index %>"><% text %></label>' +
                        '<div data-target-id="label_<% input_id %>_<% index %>" class="js-info-icon-popup"><% description %></div>' +
                        '<%&additionalHtml%>' +
                        '</li>';

                    var permissionOptions = {
                        input_id: "grant_employee_permissions_to_organisation_permissions",
                        index: index,
                        text: permissionData['permissionName'],
                        description: permissionData['permissionDescription']
                    };
                }

                additionalPermissionsHtml += Mustache.render(permissionHtmlTemplate, permissionOptions);

                index++;
            });

            additionalPermissionsHtml += '</ul>';
            additionalPermissionsHtml += '</div>';

            return additionalPermissionsHtml;
        }

        function loadPermissions(organisationId, employeeId) {
            var getAllowedEmployeePermissionsUrl = '/employee-permissions/list-allowed/' + organisationId + '/' + employeeId;

            $.ajax({
                url: getAllowedEmployeePermissionsUrl,
                dataType: 'json'
            }).done(function (allowedPermissionsList) {
                if (allowedPermissionsList.nbOfAvailablePermissions == 0) {
                    $info.text('You can\'t change permissions for given user');
                } else {
                    var index = 0;
                    var administrationPermissionsHtml = '';
                    var additionalPermissionsHtml = '';

                    if (Object.keys(allowedPermissionsList.administration).length) {
                        administrationPermissionsHtml += getAdministrativePermissionsHtml(index, allowedPermissionsList);
                        index = Object.keys(allowedPermissionsList.administration).length;
                    }

                    additionalPermissionsHtml += getAdditionalPermissionsHtml(index, allowedPermissionsList);

                    var html = administrationPermissionsHtml + additionalPermissionsHtml;

                    $info.text('');
                    $('.add-permissions-to-organisation__permissions').html(html).show();
                    $('#grant_employee_permissions_to_organisation_buttons, .js-add-permissions-to-organisation-buttons').show();
                    $('.js-info-icon-popup').infoIconPopup();
                }
            }).fail(function () {
                $errors.text('<ul><li>An error has occurred while loading permissions. Please try again.</li></ul>');

                $('#grant_employee_permissions_to_organisation_employeeName').val('');
            });
        }

        function getFormType() {
            if ($('#grant_employee_permissions_to_organisation_organisationId').val()) {
                return 'add_for_employee';
            } else {
                return 'add_for_organisation';
            }
        }

        if (getFormType() == 'add_for_employee') {
            $info.text('Please choose user');
        } else {
            $info.text('Please choose organisation');
        }

        $('#grant_employee_permissions_to_organisation_buttons, .js-add-permissions-to-organisation-buttons').hide();

        $('#grant_employee_permissions_to_organisation_employeeName').autocomplete({
            source: '/employee/search-autocomplete',
            minLength: 3,
            delay: 500,
            search: function (event, ui) {
                $('.add-permissions-to-organisation__permissions').html('').hide();
                $('#grant_employee_permissions_to_organisation_buttons, .js-add-permissions-to-organisation-buttons').hide();

                var trimmedSearchTerm = event.target.value.trim();

                if (trimmedSearchTerm.length < 3) {
                    return false;
                }

                $info.text('... loading users, please wait');
            },
            response: function (event, ui) {
                $info.text('Please choose user');
            },
            select: function (event, ui) {
                var employeeId = ui.item.id;
                var organisationId = $('#grant_employee_permissions_to_organisation_organisationId').val();
                if (employeeId) {
                    $info.text('... loading permissions please wait');
                    $('#grant_employee_permissions_to_organisation_employeeId').val(employeeId);
                    loadPermissions(organisationId, employeeId);
                } else {
                    $info.text('Please choose correct user');
                }
            }
        });

        $('#grant_employee_permissions_to_organisation_organisationName').autocomplete({
            source: '/organisation/search-country-autocomplete',
            minLength: 3,
            delay: 500,
            search: function (event, ui) {
                $('.add-permissions-to-organisation__permissions').html('').hide();
                $('#grant_employee_permissions_to_organisation_buttons').hide();
                $info.text('... loading organisations please wait');
            },
            response: function (event, ui) {
                $info.text('Please choose an organisation');
            },
            select: function (event, ui) {
                var employeeId = $('#grant_employee_permissions_to_organisation_employeeId').val();
                var organisationId = ui.item.id;
                if (organisationId) {
                    $info.text('... loading permissions please wait');
                    $('#grant_employee_permissions_to_organisation_organisationId').val(organisationId);
                    loadPermissions(organisationId, employeeId);
                } else {
                    $info.text('Please choose an organisation');
                }
            }
        });

        $('#grant_employee_permissions_to_organisation_form').submit(function (e) {
            e.preventDefault();

            $errors.html('');

            var $this = $(this);

            $.ajax({
                type: 'POST',
                url: $this.attr('action'),
                dataType: 'json',
                data: $this.serialize(),
                success: function (response) {
                    if (response.status == 'OK') {
                        window.location = response.redirect_url;
                    } else {
                        var errorsHtml = '<div class="error-messages">Please correct the following errors:</div>';
                        errorsHtml += '<ul class="error-messages">';
                        for (var i = 0; i < response.errors.length; i++) {
                            errorsHtml += Mustache.render('<li><% error %></li>', {error: response.errors[i]});
                        }
                        errorsHtml += '</li>';
                        $errors.html(errorsHtml);
                        $('#grant_employee_permissions_to_organisation_save').protectClickTwiceReset();
                    }
                }
            }).fail(function () {
                $errors.html('<ul class="error-messages"><li>An error has occurred while adding permissions. Please try again.</li></ul>');

                $('#grant_employee_permissions_to_organisation_save').protectClickTwiceReset();
            });
        });
    };
}(jQuery));