(function ($) {
    $.fn.reloadPageUsingUrlBuiltFromFormFilters = function (userOptions) {
        userOptions = userOptions || {};

        var $filterForm = $(this);
        var filterFormName = $filterForm.attr('name');
        var filterLocalStorageKey = userOptions.filterLocalStorageKey;

        $filterForm.submit(function () {
            var filters = getFilters();
            HORUS.localStorageSetItemIfUserGranted(filterLocalStorageKey, JSON.stringify(filters));

            var filterParams = '';
            for (var filter in filters) {
                filterParams = filterParams + '/' + filters[filter];
            }
            window.location = userOptions.formUrl + filterParams;
            return false;

        });

        if (! HORUS.cookieManager.cookiePreferences.isLocalStorageGranted()) {
            $filterForm.before(HORUS.cookieManager.getLocalStorageDisabledMessageElement(false));
        }

        var filterFormStorageData = JSON.parse(localStorage.getItem(filterLocalStorageKey)) || null;

        if (! filterFormStorageData) {
            return;
        }

        var submitForm = false;

        var filters = getFilters();
        for (var filter in filters) {
            var selector = '#' + filterFormName +'_' + filter + ':visible';
            if (selector && filters[filter] !== filterFormStorageData[filter]) {
               $(selector).val(filterFormStorageData[filter]);
               submitForm = true;
            }
        }

        if (submitForm) {
            $filterForm.submit();
        }
        function getFilters() {
            var filters = {};

            var formData = $filterForm.serializeArray();
            for (var i = 0; i < formData.length; i++) {
                var regExp = /\[([^)]+)\]/;
                var matches = regExp.exec(formData[i]['name']);
                if (matches) {
                    var filterName = matches[1];
                } else {
                    var filterName = formData[i]['name'];
                }
                filters[filterName] = formData[i]['value'];
            }
            return filters;
        }
    };
}(jQuery));