(function ($) {
    $.fn.schoolDirectorPicker = function() {

        var $schoolDirectorIdField = $('#' + $(this).attr('id') + 'Id');

        $(this).autocomplete({
            source: '/autocomplete/school-directors',
            minLength: 3,
            delay: 1000,
            search: function (event, ui) {
                $schoolDirectorIdField.val('');

                var trimmedSearchTerm = event.target.value.trim();

                if (trimmedSearchTerm.length < 3) {
                    return false;
                }
            },
            select: function (event, ui) {
                $schoolDirectorIdField.val(ui.item.id);
            }
        });

        $(this).addInfoIconPopupWithContent('Start typing to search for a school director and select from the list.');

        return this;
    };
}(jQuery));