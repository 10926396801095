window.HORUS = window.HORUS || {};

HORUS.dialog = function(message, buttons, options) {
    if (!(typeof message == 'string')) {
        throw new Error('First constructor argument must be the string message');
    }

    if (buttons !== undefined && Object.prototype.toString.call(buttons) != '[object Array]') {
        throw new Error(
            'Second constructor argument must be either be "undefined" if one ' +
            'button labelled "OK" is required or an array of buttons'
        );
    }

    this._canCloseWithEscapeOrBackgroundClick = false;

    if (buttons === undefined) {
        buttons = [{ label: 'OK', isDefault: true }];

        this._canCloseWithEscapeOrBackgroundClick = true;
    }

    this.settings = {
        'wideBody': typeof options === 'object' ? options.wideBody : false
    };

    for (var i = 0; i < buttons.length; i++) {
        if (typeof buttons[i] != 'object') {
            throw new Error('Each button must be provided as an object literal');
        }

        if (buttons[i].label === undefined) {
            throw new Error('Each button must contain a "label" property');
        }
    }

    this._message = message;
    this._buttons = buttons;

    this._isShown(false);

    var thisDialog = this;
    document.addEventListener('keydown', function(e) {
        if (! thisDialog._isShown()) {
            return;
        }

        if (thisDialog._canCloseWithEscapeOrBackgroundClick && e.key === 'Escape') {
            thisDialog.hide();
        }

        if (e.key === 'Tab') {
           var focusableElements = thisDialog._getElement(true).find(
                'a, input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])'
            );

            var focusableElementsLastIndex = focusableElements.length - 1;
            var focusedItemIndex = Array.from(focusableElements).indexOf(document.activeElement);

            if (! e.shiftKey && focusedItemIndex === focusableElementsLastIndex) {
                focusableElements.get(0).focus();

                e.preventDefault();
            }

            if (e.shiftKey && focusedItemIndex === 0) {
                focusableElements.get(focusableElementsLastIndex).focus();

                e.preventDefault();
            }
        }
    });
};

HORUS.dialog.prototype.show = function() {
    var $element = this._getElement(true);

    $element.appendTo('body');
    this._bindButtons();

    this._rememberPreviousFocus();
    this._isShown(true);
    this._listenForBackgroundClick();

    var defaultButtonIndex = 0;
    for (var i = 0; i < this._buttons.length; i++) {
        if (this._buttons[i].isDefault) {
            defaultButtonIndex = i;
            break;
        }
    }
    $element.find('button').eq(defaultButtonIndex).focus();
};

HORUS.dialog.prototype.hide = function() {
    this._getElement(true).remove();

    this._isShown(false);
    this._restorePreviousFocus();
};

HORUS.dialog.prototype._getElement = function(asJquery) {
    if (!this._$element) {
        var $buttons = $([]);

        for (var i = 0; i < this._buttons.length; i++) {
            $buttons = $buttons.add($('<button />').text(this._buttons[i].label));
        }

        this._$element = $('<div />').addClass('dialog');

        var bodyClass = this.settings.wideBody ? 'dialog__body dialog__body--wide' : 'dialog__body';

        this._$element.append(
            $('<div />').addClass(bodyClass).append(
                $('<div />').addClass('dialog__message').attr('id', 'dialog-message').text(this._message),
                $('<div />').addClass('dialog__buttons').append($buttons)
            ).attr('role', 'alertdialog').attr('aria-describedby', 'dialog-message')
        );
    }

    return (asJquery) ? this._$element : this._$element[0];
};

HORUS.dialog.prototype._bindButtons = function() {
    for (var i = 0; i < this._buttons.length; i++) {
        var eventData = {element: this, callback: this._buttons[i].callback};
        this._getElement(true).find('button').eq(i).click(eventData, bindButton);
    }

    function bindButton(event) {
        event.data.element.hide();

        if (event.data.callback) {
            event.data.callback();
        }
    }
};

HORUS.dialog.prototype._isShown = function(isShown) {
    if (typeof isShown === 'undefined') {
        return this._isShownVal === true;
    }

    this._isShownVal = isShown === true;
};

HORUS.dialog.prototype._rememberPreviousFocus = function() {
    this._previousFocus = document.activeElement;
}

HORUS.dialog.prototype._restorePreviousFocus = function() {
    $(this._previousFocus).focus();
}

HORUS.dialog.prototype._listenForBackgroundClick = function () {
    var $element = this._getElement(true);
    var dialog = this;

    $element.click(function (e) {
        if (dialog._canCloseWithEscapeOrBackgroundClick && $(e.target).get(0) === $element.get(0)) {
            dialog.hide();
        }
    });
}