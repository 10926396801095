(function ($) {
    $.fn.mainNavigation = function () {
        var $overallNavigationContainer = $(this);

        var $smallScreenNavigationContainer = $('.nav-small-screen__menu-content', $overallNavigationContainer);
        var $largeScreenNavigationContainer = $('.nav-large-screen', $overallNavigationContainer);

        // Set up button to show or hide the small screen navigation menu
        $('.nav-small-screen__menu', $overallNavigationContainer).click(function () {
            var isExpanded = $(this).attr('aria-expanded') === 'true';
            if (isExpanded) {
                $smallScreenNavigationContainer.slideUp(100);
            } else {
                $smallScreenNavigationContainer.slideDown(100);
            }
            $(this).attr('aria-expanded', ! isExpanded);
        });

        // Set up buttons to show or hide sections within the small screen navigation menu
        $('> button[aria-expanded]', $smallScreenNavigationContainer).click(function () {
            var $expandableSection = $(this).next();
            var isExpanded = $expandableSection.is(':visible');
            $expandableSection.slideToggle(200);
            $(this).attr('aria-expanded', ! isExpanded);
        });

        // Set up buttons to show or hide sections within the large screen navigation menu
        $('> button[aria-expanded]', $largeScreenNavigationContainer).click(function () {
            var $expandableSection = $(this).next();
            var isExpanded = $expandableSection.is(':visible');
            var $expandedSections = $('.expandable-section:visible', $largeScreenNavigationContainer);
            if ($expandedSections.length > 0) {
                $expandedSections.slideUp(100, function () {
                    $(this).prev().attr('aria-expanded', false);
                    if (! isExpanded) {
                        $expandableSection.slideDown(300);
                    }
                });
            } else {
                $expandableSection.slideDown(300);
            }

            $(this).attr('aria-expanded', ! isExpanded);
        });

        // Hide expanded section when focus leaves the section, for the large screen navigation menu
        $('.expandable-section a', $largeScreenNavigationContainer).focusout(function () {
            var $previousLinksSection = $(this).parents('.expandable-section');
            // very short delay added to avoid the focussed element being the entire document body
            setTimeout(function () {
                // when no links are in focus within the section, hide it
                if ($('a:focus', $previousLinksSection).length === 0) {
                    $previousLinksSection.hide();
                }
            }, 100);

            $('button[aria-expanded]', $largeScreenNavigationContainer).attr('aria-expanded', false);
        });

        var closeAllExpandableSectionsInLargeScreenNavigationContainer = function() {
            $('.expandable-section', $largeScreenNavigationContainer).slideUp(100);
            $('button[aria-expanded]', $largeScreenNavigationContainer).attr('aria-expanded', false);
        };

        // Set up the close buttons for the expandable sections in the large screen navigation menu
        $('.expandable-section__close', $largeScreenNavigationContainer).click(function () {
            closeAllExpandableSectionsInLargeScreenNavigationContainer();
        });

        // Set up escape key press to close all expanded sections for the large screen navigation menu
        $(document).keyup(function(e) {
            if (e.key === "Escape") {
                closeAllExpandableSectionsInLargeScreenNavigationContainer();
            }
        });

        // Hide all expandable sections when click detected outside of navigation area
        $(document).click(function(e) {
            if (! $(e.target).parents('nav').length) {
                closeAllExpandableSectionsInLargeScreenNavigationContainer();
            }
        });
    };
}(jQuery));