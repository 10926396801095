(function ($) {
    $.fn.tabbedComponent = function (options) {
        var itemClass = 'tabbed-component__header__item';
        var selectedItemClass = 'tabbed-component__header__item--selected';
        var bodySectionClass = 'js-tabbed-component-body-section';

        // show the content for the initially selected tab
        $('.' + bodySectionClass).hide();
        var initialBodySection = $('.' + selectedItemClass + ' a').data('body-section');
        $('.' + bodySectionClass + '-' + initialBodySection).show();

        var $items = $('.' + itemClass);

        $('.' + itemClass + ' a').click(function (e) {
            e.preventDefault();

            $items.removeClass(selectedItemClass);

            $('.' + bodySectionClass).hide();

            $('.' + bodySectionClass + '-' + $(this).data('body-section')).show();

            $(this).parent('li').addClass(selectedItemClass);
        });
    };
}(jQuery));