(function ($) {
    $.fn.makeDependantOn = function (options) {
        return this.each(function () {
            if (options.showSectionValues && options.hideSectionValues) {
                throw 'You cannot use both the showSectionValues and hideSectionValues options';
            }

            var $dependantElement = $(this);
            var $providerElements = $('[name="' + options.propertyName + '"]');
            var isElementDisabled = $providerElements.attr('disabled');
            var elementType = $providerElements.prop('nodeName');
            var multiple = false;

            if (
                (elementType === 'INPUT' && $providerElements.attr('type') == 'checkbox')
                || (elementType === 'SELECT' && $providerElements.attr('multiple'))
            ) {
                multiple = true;
            }

            var handle = function (show) {
                if (show) {
                    $dependantElement.show();
                    if (!isElementDisabled) {
                        $dependantElement.find('textarea, input, select').attr('disabled', false);
                    }
                } else {
                    $dependantElement.hide();
                    if (!isElementDisabled) {
                        $dependantElement.find('textarea, input, select').attr('disabled', true);
                    }
                }
            };

            var elementTypeSelector = '';
            var optionSelector = '';

            switch (elementType) {
                case 'INPUT':
                    elementTypeSelector = 'checked';

                    break;

                case 'SELECT':
                    elementTypeSelector = 'selected';
                    optionSelector = ' ' + 'option';

                    break;

                default:

                    throw 'The element for the propertyName passed is not supported.';
            }

            $providerElements.on('makeDependantOn:change', function () {
                var chosenValues = [];

                if (multiple) {
                    chosenValues = $('[name="' + options.propertyName + '"]' + optionSelector + ':' + elementTypeSelector).map(function(){
                        return $(this).val();
                    }).get();
                } else {
                    chosenValues = [$('[name="' + options.propertyName + '"]' + optionSelector + ':' + elementTypeSelector).val()];
                }

                var show = null;

                if (options.showSectionValues) {
                    show = false;
                }

                if (options.hideSectionValues) {
                    show = true;
                }

                $.each(chosenValues, function (index, value) {
                    if (options.showSectionValues) {
                        if ($.inArray(value, options.showSectionValues) > -1) {
                            show = true;
                        }
                    }

                    if (options.hideSectionValues) {
                        if ($.inArray(value, options.hideSectionValues) > -1) {
                            show = false;
                        }
                    }
                });

                handle(show);
            });

            $providerElements.change(function () {
                $(this).trigger('makeDependantOn:change');
            });

            $providerElements.trigger('makeDependantOn:change');
        });
    };
}(jQuery));