(function ($) {
    $.fn.floatingNavigation = function () {
        var $navFloating = $(this);

        if ($navFloating.length) {
            var $navFloatingHeader = $('.nav-floating__button-header', $navFloating);
            var $navFloatingMore = $('.nav-floating__more', $navFloating);

            var showFloatingMenu = function () {
                $navFloatingMore.show();
                $navFloatingHeader.attr('aria-expanded', true);
            };

            var hideFloatingMenu = function () {
                $navFloatingMore.hide();
                $navFloatingHeader.attr('aria-expanded', false);
            };

            var setExpandOnHoverStatus = function (expandOnHover) {
                if (expandOnHover) {
                    $navFloatingMore.removeClass('js-disable-hover');
                } else {
                    $navFloatingMore.addClass('js-disable-hover');
                }
            };

            var isExpanded = function () {
                return $navFloatingHeader.attr('aria-expanded') === 'true';
            }

            // hide the menu when rest of page is clicked
            $('html').click(function(e) {
                if (! $(e.target).parents('.nav-floating__container').length) {
                    hideFloatingMenu();
                    setExpandOnHoverStatus(true);
                }
            });

            $navFloatingHeader.click(function (e) {
                if (isExpanded() && $navFloatingMore.hasClass('js-disable-hover')) {
                    hideFloatingMenu();
                    setExpandOnHoverStatus(true);
                } else {
                    showFloatingMenu();
                    setExpandOnHoverStatus(false);
                }
            });

            $navFloating.hover(
                function () {
                    $changeRotationNotice.hide();
                    if (! $navFloatingMore.hasClass('js-disable-hover')) {
                        showFloatingMenu();
                    }
                },
                function () {
                    if (! $navFloatingMore.hasClass('js-disable-hover')) {
                        hideFloatingMenu();
                    }
                }
            );

            // Hide expanded section when focus leaves the section
            $('a, button', $navFloating).focusout(function () {
                // very short delay added to avoid the focussed element being the entire document body
                setTimeout(function () {
                    // when no links are in focus within the section, hide it
                    if ($('a:focus', $navFloatingMore).length === 0) {
                        hideFloatingMenu();
                        setExpandOnHoverStatus(true);
                    }
                }, 100);
            });

            // Set up escape key press to close all expanded menu
            $(document).keyup(function(e) {
                if (e.key === "Escape") {
                    hideFloatingMenu();
                    setExpandOnHoverStatus(true);
                }
            });

            // This is only applicable for the portfolio floating menu, slowly hides a info tip
            var $changeRotationNotice = $('.change-rotation-notice', $navFloating);
            $changeRotationNotice.delay(3000).slideUp(2000);
        }
    };
}(jQuery));