(function ($) {
    $.fn.traineeRotations = function ($rotationsTarget) {
        var $this = $(this);
        var rotationIdValue = $('#' + $rotationsTarget.attr('id') + '_value').val();
        var $parentForm = $rotationsTarget.closest('form');
        var rotationsHtmlDefault = '<p>Please select a Foundation Doctor.</p>';
        var template = $('#rotationsIdTemplate').html();

        $this.on('traineePicker:search', function (event, ui) {
            $rotationsTarget.html(rotationsHtmlDefault);
        });

        function loadRotations(traineeId) {
            $rotationsTarget.html('<p>Loading <img src="/images/loading-indicator.gif"></p>');

            // Don't allow form to be submitted while loading rotations as this may overwrite
            // data previously submitted if editing form
            $parentForm.on('submit.horus', function (e) {
                e.preventDefault();
            });

            try {
                HORUS.ajax.traineeRotations(traineeId, function (responseData) {
                    var rotationsHtml = '';
                    var rotationsInfo;
                    var checked = '';
                    var templateData = {rotations: []};

                    if (responseData.count < 1) {
                        rotationsHtml = '<p>No rotations found for this foundation doctor.</p>';
                    } else {
                        for (var i = 0; i < responseData.count; i++) {
                            rotationsInfo = responseData.data[i];
                            checked = (rotationsInfo.rotationId == rotationIdValue);

                            templateData.rotations.push({
                                checked: checked,
                                i: i,
                                id: rotationsInfo.rotationId,
                                rotationFullDescription: rotationsInfo.rotationFullDescription
                            });
                        }

                        rotationsHtml = Mustache.render(template, templateData);
                    }

                    $rotationsTarget.html(rotationsHtml);
                    $rotationsTarget.trigger('traineeRotations:loaded');
                }).always(function () {
                    $parentForm.off('submit.horus');
                });
            } catch (e) {
                $rotationsTarget.html(rotationsHtmlDefault);
                $parentForm.off('submit.horus');
            }
        }

        if ($this.val()) {
            var foundationDoctorIdHiddenFieldId = $this.attr('id') + 'Id';
            var traineeId = $('#' + foundationDoctorIdHiddenFieldId).val();
            loadRotations(traineeId);
        } else {
            $rotationsTarget.html(rotationsHtmlDefault);
        }

        $this.on('traineePicker:select', function() {
            var foundationDoctorIdHiddenFieldId = $this.attr('id') + 'Id';
            var traineeId = $('#' + foundationDoctorIdHiddenFieldId).val();
            loadRotations(traineeId);
        });



        return this;
    };
}(jQuery));